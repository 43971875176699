import styles from "../../assets/css/popups/addinvoicepopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function AddInvoicePopUp(seller) {
  const [data, setData] = useState({
    serial: "",
    client: "",
    gurantee: "",
    name: "",
    date: "",
    total: 0,
    paid: 0,
    products: []
  });
  const [overlay, setOverlayState] = useState(false);

  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { addInvoiceWidgetState: false } });
  }
  async function upload(){
      if (data.products.length === 0) {
      } else {
        let functions = new JSFunctions();
        // ++++
        if (data.total <= 0 || data.paid.length <= 0 || data.date.length === 0) {
            Toast.error("يجب إدخال البيانات كاملة")
        }else if(data.total - data.paid < 0){
            Toast.error("يجب أن يكون الإجمالي أصغر من المدفوع")
        }
        let state;
        if (data.total !== data.paid) {
            state = "opened";
        }else {
            state = "closed";
        }
    
        let uploadData  = {
            "date": data.date,
            "last_edited_date": data.date,
            "total_price": data.total,
            "paid": data.paid,
            "state": state,
            "products": data.products,
            "history": [{"date": data.date,"added": data.paid,"paid": data.paid,"net": data.total-data.paid}],
        };
        uploadData = JSON.stringify(uploadData);
        console.log(uploadData)

        setOverlayState(true);
        let res = await functions.addInvoice(uploadData,seller.seller);
        setOverlayState(false);
        if (!res) {
            Toast.error("حدث خطأ.");
        } else {
            Toast.success("تم إضافة الفاتورة");
        }
      }
  }
  async function addProduct() {
    if(data.name.length !== 0 && data.serial.length !== 0  && data.client.length !== 0 && data.gurantee.length !== 0) {
        let is_exist = false;
        data.products.map((ele)=>{
            if (ele.serial === data.serial) {
                is_exist = true;
            }
        })
        if (is_exist) {
            Toast.error("لقد قمت بإدخال هذا المنتج بالفعل")
        }else {
            setData({...data,
                products: [...data.products, {
                name: data.name,
                serial: data.serial,
                client: data.client,
                gurantee: data.gurantee,
            }],serial: ""});
            Toast.success("تم إضافة المنتج للجدول, إضغط حفظ لحفظ الفاتورة ");
        }
    }else {
        Toast.error("يجب إكمال البيانات أولا.")
    }
  }

  const handleCondition = (e) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control";
    }
    if (e.key === condition) {
      addProduct();
    }
  };
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>إضافة فاتورة</h2>
          <div onClick={() => cancle("addProductWidgetState")}>
            <BackBtn />
          </div>
        </div>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.name}
            onChange={(ele) => {
              setData({ ...data, name: ele.target.value });
            }}
            placeholder="الإسم"
          ></input>
        </div>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.client}
            onChange={(ele) => {
              setData({ ...data, client: ele.target.value });
            }}
            placeholder="الموكل"
          ></input>

          <input
            className={styles["pop-up-input"]}
            value={data.gurantee}
            onChange={(ele) => {
              setData({ ...data, gurantee: ele.target.value });
            }}
            placeholder="الضمان"
          ></input>
        </div>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.serial}
            onKeyDown={(e)=>handleCondition(e)}
            onChange={(ele) => {
              setData({ ...data, serial: ele.target.value });
            }}
            placeholder="السيريال"
          ></input>
                  <button  onClick={addProduct}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M26.82 8.4118V8.41317V19.5865C26.82 21.9137 26.1282 23.7112 24.9131 24.9263C23.6979 26.1414 21.9005 26.8332 19.5733 26.8332H8.41329C6.08607 26.8332 4.2888 26.1414 3.07373 24.9248C1.8586 23.7082 1.16663 21.9074 1.16663 19.5732V8.41317C1.16663 6.08594 1.85839 4.28852 3.07351 3.07339C4.28864 1.85827 6.08606 1.1665 8.41329 1.1665H19.5866C21.9139 1.1665 23.7112 1.85831 24.9245 3.07317C26.1378 4.28793 26.8264 6.08488 26.82 8.4118ZM15.5 19.3332V15.4998H19.3333C20.1561 15.4998 20.8333 14.8226 20.8333 13.9998C20.8333 13.177 20.1561 12.4998 19.3333 12.4998H15.5V8.6665C15.5 7.84369 14.8228 7.1665 14 7.1665C13.1772 7.1665 12.5 7.8437 12.5 8.6665V12.4998H8.66663C7.84382 12.4998 7.16663 13.177 7.16663 13.9998C7.16663 14.8226 7.84382 15.4998 8.66663 15.4998H12.5V19.3332C12.5 20.156 13.1771 20.8332 14 20.8332C14.8228 20.8332 15.5 20.156 15.5 19.3332Z"
              fill="white"
              stroke="white"
            />
          </svg>
          إضافة
        </button>
        </div>
        <div className={styles["gp"]}>
          <input
            type={"date"}
            className={styles["pop-up-input"]}
            value={data.date}
            onChange={(ele) => {
              setData({ ...data, date: ele.target.value });
            }}
            placeholder="التاريخ"
          ></input>

        
        </div>
        <div className={styles["gp"]}>
        <input
            className={styles["pop-up-input"]}
            value={data.total}
            onChange={(ele) => {
              setData({ ...data, total: ele.target.value });
            }}
            placeholder="الإجمالي"
          ></input>
        <input
            className={styles["pop-up-input"]}
            value={data.paid}
            onChange={(ele) => {
              setData({ ...data, paid: ele.target.value });
            }}
            placeholder="المدفوع"
          ></input>
       
        </div>
        <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>الإسم</th>
                <th>الكود</th>
                <th>الموكل</th>
                <th>الضمان</th>
            </tr>
        </thead>
        <tbody>
            {data.products.map((ele,index)=>{
                return <tr>
                    <td className={styles["index"]}>{index +1}</td>
                    <td>{ele.name}</td>
                    <td>{ele.serial}</td>
                    <td>{ele.client}</td>
                    <td>{ele.gurantee}</td>
                </tr>
            })}
        </tbody>
      </table>
      {data.products.length !== 0 ? <button onClick={upload}>حفظ</button>: null}
        
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default AddInvoicePopUp;
