import Bar from "../components/bars";
import Invoices from "../components/invoices";
import SellPoint from "../components/sellpoints";

function InvoicesPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"الفواتير": "/invoices"}
    ]
    return <Bar pagename="الفواتير" breadCrump={breadCrump} child={<Invoices/>}></Bar>
}

export default InvoicesPage;