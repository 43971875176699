import Bar from "../components/bars";
import Orders from "../components/orders";
import Reports from "../components/reports";

function ReportsPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"التقارير": "/reports"}
    ]
    return (
        <>
           <Bar pagename="التقارير" notification={"1"} breadCrump={breadCrump} child={<Reports
           />}/>
        </>
    )
}

export default ReportsPage;