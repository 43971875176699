import Bar from "./bars";

function Welcome(){
  let breadCrump  = [
    {"الرئيسية": "/welcome"}
  ]
    return (
      <>
        <Bar pagename="welcome" notification={"1"} breadCrump={breadCrump} child={<></>}/>
      </>
    )
}

export default Welcome;