import Bar from "../components/bars";
import SellPoint from "../components/sellpoints";

function SellPointPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"نقاط البيع": "/sellpoints"}
    ]
    return <Bar pagename="نقطة البيع" breadCrump={breadCrump} child={<SellPoint/>}></Bar>
}

export default SellPointPage;