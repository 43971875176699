import './App.css';
import LoginPage from './pages/Login';
import Dashboard from './components/dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Welcome from './components/welcome';
import SellPointPage from './pages/SellPoints';
import CustomerInvoicePage from './pages/CustomerInvoice';
import Invoice from './pages/Invoice';

import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit';
import SellerInvoicePage from './pages/SellerInvoice';
import StoragePage from './pages/Storage';
import InvoicesPage from './pages/Invoices';
import MerchantsAndClients from './components/merchantsandclients';
import MerchantsAndClientsPage from './pages/MerchantsAndClients';
import MerchantOrClientInvoices from './pages/MerchantOrClientInvoices';
import EmployeesPage from './pages/Employees';
import ReturnsPage from './pages/Returns';
import ExpensesPage from './pages/Expenses';
import OrdersPage from './pages/Orders';
import ReportsPage from './pages/Reports';
import WareHouseReportPage from './pages/WareHouseReport';



const reducer = (state = {
    customerName: "",
    customerPhone: "",
    products: [],
    toggle: true,
    date: "",
    time: ""
},action) => {
    switch(action.type) {
        case "restart":
            return { ...state, customerName: "",
             customerPhone: "",
             products: []}
        default:
            return {...state, ...action.payload};
    }
}
const store = configureStore({reducer: reducer});

// export const api = "http://192.168.137.1/puzzle-tech/";
export const api = "https://puzzletechsystem.com/api/";

export class JSFunctions {
  async searchByCode(code,limit){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}search_by_code.php?name=${name}&password=${password}&serial=${code}&limit=${limit}`);
              let responseData = JSON.parse(await response.text());   
              if (responseData.state === false) {
                  alert("خطأ");
              } else if (responseData.state === "Not found") {
                  return false
              } else {
                  return responseData
              }
          }catch (e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
          
      }
      alert("خطأ")
      
  }
  async searchByName(productName){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
            console.log(`${api}search_by_name.php?name=${name}&password=${password}&product_name=${productName}`)
              let response = await fetch(`${api}search_by_name.php?name=${name}&password=${password}&product_name=${productName}`);
              let responseData = JSON.parse(await response.text());   
              if (responseData.state === false) {
                  alert("خطأ");
              } else if (responseData.state === "Not found") {
                  return false
              } else {
                  return (responseData) 
              }
          }catch(e){
            console.log(e)
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async searchCategory(data){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
          console.log(`${api}search_category.php?name=${name}&password=${password}&category_name=${data.name}&category_client=${data.client}&category_gurantee=${data.gurantee}&category_date=${data.date}`)
            let response = await fetch(`${api}search_category.php?name=${name}&password=${password}&category_name=${data.name}&category_client=${data.client}&category_gurantee=${data.gurantee}&category_date=${data.date}`);
            let responseData = JSON.parse(await response.text());   
            if (responseData.state === false) {
                alert("خطأ");
            } else if (responseData.state === "Not found") {
                return false
            } else {
                return responseData;
            }
        }catch{
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
    
  }
  async editCategoryPrice (productName,price,customerPrice,gurantee,client,date,category){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
            console.log(`${api}edit_category_price.php?name=${name}&password=${password}&product_name=${productName}&price=${price}&customer_price=${customerPrice}&gurantee=${gurantee}&client=${client}&date=${date}&category=${category}`)
              let response = await fetch(`${api}edit_category_price.php?name=${name}&password=${password}&product_name=${productName}&price=${price}&customer_price=${customerPrice}&gurantee=${gurantee}&client=${client}&date=${date}&category=${category}`);
              let responseData = JSON.parse(await response.text());   
              if (responseData.state === false) {
                  return false;
              } else if (responseData.state === "Not found") {
                  return {"state": "Not found"}
              } else {
                  return responseData
              }
          }catch{
              await new Promise(resolve => setTimeout(resolve, 1000));
          }            
      }
      alert("خطأ");
      
  }
  async editProduct(code,data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}edit_product_price.php?name=${name}&password=${password}&product_code=${code}&data=${data}`)

      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}edit_product_price.php?name=${name}&password=${password}&product_code=${code}&data=${data}`);
              let responseData = JSON.parse(await response.text());   
              if (responseData.state === false) {
                  alert("خطأ");
                  return responseData.state
              } else if (responseData.state === "Not found") {
                  return false;
              } else {
                  return responseData
              }
          }catch{
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async deleteProduct (code,limit){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}del_product.php?name=${name}&password=${password}&product_code=${code}&limit=${limit}`);
              console.log(`${api}del_product.php?name=${name}&password=${password}&product_code=${code}&limit=${limit}`)
              let responseData = JSON.parse(await response.text());   
              if (responseData.state === false) {
   
                  
              } else if (responseData.state === "Not found") {
                  return {"state": "Not found"}
              } else {
                  return responseData
              }
          }catch{
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async addProduct (products){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");

    let postData = new FormData();
    postData.append('name', name);
    postData.append('password', password);
    postData.append('products', products);

    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}add_product.php`, {
                method: 'POST',
                body: postData
            });
            let responseData = await response.json();   
            if (!responseData.state) {
                return false;
            } else if (responseData.state) {
                window.location.reload();
                return true;
            }
        } catch(error) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
      
  }

  async finishCustomerSell(data) {
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");

    let postData = new FormData();
    postData.append('name', name);
    postData.append('password', password);
    postData.append('data', data);

    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}finish_customer_sell.php`, {
                method: 'POST',
                body: postData
            });
            let responseData = await response.json();   
            return responseData;
        } catch(error) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
  }
  async  finishSellerSell(data) {
        let name = localStorage.getItem("username");
        let password = localStorage.getItem("password");
    
        let postData = new FormData();
        postData.append('name', name);
        postData.append('password', password);
        postData.append('data', data);
    
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}finish_seller_sell.php`, {
                    method: 'POST',
                    body: postData
                });
                if (response.ok) {
                    let responseData = await response.json();
                    return responseData;
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch(error) {
                console.error('Fetch error:', error);
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        alert("خطأ");
  }

  async invoiceSearchByName(invoice_name){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}invoice_search_by_name.php?name=${name}&password=${password}&invoice_name=${invoice_name}`)

      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}invoice_search_by_name.php?name=${name}&password=${password}&invoice_name=${invoice_name}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch{
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async invoiceSearchByCode(invoice_code){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");

      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}invoice_search_by_code.php?name=${name}&password=${password}&invoice_code=${invoice_code}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch{
              await new Promise(resolve => setTimeout(resolve, 1000));
          }           
      }  
      alert("خطأ");
      

  }
  async deleteInvoice (code){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}del_invoice.php?name=${name}&password=${password}&code=${code}`);
            console.log(`${api}del_invoice.php?name=${name}&password=${password}&code=${code}`)
            let responseData = JSON.parse(await response.text());   
            return responseData
        }catch{
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
    
  }

  async deleteReturn (code){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}del_return.php?name=${name}&password=${password}&product_code=${code}`);
            console.log(`${api}del_return.php?name=${name}&password=${password}&product_code=${code}`)
            let responseData = JSON.parse(await response.text());   
            return responseData
        }catch{
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
    
  }
  async deleteExpense (id){
        let name = localStorage.getItem("username");
        let password = localStorage.getItem("password");
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}del_expense.php?name=${name}&password=${password}&id=${id}`);
                console.log(`${api}del_expense.php?name=${name}&password=${password}&id=${id}`)
                let responseData = JSON.parse(await response.text());   
                return responseData
            }catch{
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        alert("خطأ");
        
  }
    

  async invoiceSearchByDate(date){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}invoice_search_by_date.php?name=${name}&password=${password}&date=${date}`);
              let responseData = JSON.parse(await response.text());  
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async returnsSearchDate(date){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}returns_search_date.php?name=${name}&password=${password}&date=${date}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}returns_search_date.php?name=${name}&password=${password}&date=${date}`);
              let responseData = JSON.parse(await response.text());    
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async addReturn(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}add_return.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}add_return.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`);
              let responseData = JSON.parse(await response.text());
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async expenseSearch(date){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}expense_search_date.php?name=${name}&password=${password}&date=${date}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}expense_search_date.php?name=${name}&password=${password}&date=${date}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async addExpense(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}add_expense.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}add_expense.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`);
              let responseData = JSON.parse(await response.text());
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async getEmployees(){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}get_employees.php?name=${name}&password=${password}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}get_employees.php?name=${name}&password=${password}`);
              let responseData = JSON.parse(await response.text());
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async editEmployeeData(data){
   
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}update_employee_data.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`)
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}update_employee_data.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async giveSalary(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      console.log(`${api}give_salary.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`);
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}give_salary.php?name=${name}&password=${password}&data=${JSON.stringify(data)}`);
              let responseData = JSON.parse(await response.text());
              return responseData;   
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }  
      alert("خطأ");
      
  }
  async createMonthlyReport(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}create_report.php?name=${name}&password=${password}&data=${JSON.stringify(data)}&type=0`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async createYearlyReport(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 1; i++) {
          try {
              let response = await fetch(`${api}create_report.php?name=${name}&password=${password}&data=${JSON.stringify(data)}&type=1`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async createCustomReport(data){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}create_report.php?name=${name}&password=${password}&data=${JSON.stringify(data)}&type=2`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async searchOrder(date){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}search_order.php?name=${name}&password=${password}&date=${date}`);
              console.log(`${api}search_order.php?name=${name}&password=${password}&date=${date}`)
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async collectOrder(id){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}collect_order.php?name=${name}&password=${password}&id=${id}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async deliverOrder(id){
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              let response = await fetch(`${api}deliver_order.php?name=${name}&password=${password}&id=${id}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async addOrder(data){
      data = JSON.stringify(data)
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              console.log(`${api}add_order.php?name=${name}&password=${password}&data=${data}`)
              let response = await fetch(`${api}add_order.php?name=${name}&password=${password}&data=${data}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async deleteOrder(id){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}del_order.php?name=${name}&password=${password}&id=${id}`);
            console.log(`${api}del_order.php?name=${name}&password=${password}&id=${id}`)
            let responseData = JSON.parse(await response.text());   
            return responseData
        }catch{
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
    
  }
  async changePassword(data){
      data = JSON.stringify(data)
      let name = localStorage.getItem("username");
      let password = localStorage.getItem("password");
      for (let i = 0; i < 100; i++) {
          try {
              console.log(`${api}change_password.php?name=${name}&password=${password}&data=${data}`)
              let response = await fetch(`${api}change_password.php?name=${name}&password=${password}&data=${data}`);
              let responseData = JSON.parse(await response.text());   
              return responseData;
          }catch(e){
              await new Promise(resolve => setTimeout(resolve, 1000));
          }
      }
      alert("خطأ");
      
  }
  async addSeller(data){

    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
            console.log(`${api}add_seller.php?name=${name}&password=${password}&seller=${data.name}&type=${data.type}&create_date=${data.date}`)
            let response = await fetch(`${api}add_seller.php?name=${name}&password=${password}&seller=${data.name}&type=${data.type}&create_date=${data.date}`);
            let responseData = JSON.parse(await response.text());   
            return responseData;
        }catch(e){
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
  }
  async delSeller(data){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        try {
            console.log(`${api}del_seller.php?name=${name}&password=${password}&seller=${data.name}`)
            let response = await fetch(`${api}del_seller.php?name=${name}&password=${password}&seller=${data.name}`);
            let responseData = JSON.parse(await response.text());   
            return responseData;
        }catch(e){
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    alert("خطأ");
  }
  async getSellers(){
    let name = localStorage.getItem("username");
    let password = localStorage.getItem("password");
    for (let i = 0; i < 100; i++) {
        let response = await fetch(`${api}get_sellers.php?name=${name}&password=${password}&with_invoices=false&seller=`);
        let responseData = JSON.parse(await response.text());
        return responseData
    }
    alert("خطأ");
  }
  async getSellerByName(sellername){
        let name = localStorage.getItem("username");
        let password = localStorage.getItem("password");
        for (let i = 0; i < 100; i++) {
            let response = await fetch(`${api}get_sellers.php?name=${name}&password=${password}&with_invoices=true&seller=${sellername}`);
            let responseData = JSON.parse(await response.text());
            return responseData
        }
        alert("خطأ");
  }
  async editSellerInvoice(sellername,updatedData,index){
        let name = localStorage.getItem("username");
        let password = localStorage.getItem("password");
        console.log(`${api}edit_seller.php?name=${name}&password=${password}&seller=${sellername}&new_data=${updatedData}&index=${index}`)
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}edit_seller.php?name=${name}&password=${password}&seller=${sellername}&new_data=${updatedData}&index=${index}`);
                let responseData = JSON.parse(await response.text());
                if (responseData.state === false || responseData.state === true) {
                    return responseData.state;
                }
            }catch {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
           
        }

  }
  async addInvoice(data, seller) {
        const name = localStorage.getItem("username");
        const password = localStorage.getItem("password");
    
        let postData = new FormData();
        postData.append('name', name);
        postData.append('password', password);
        postData.append('seller', seller);
        postData.append('data', data);
        console.log(postData)
    
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}buy_products.php`, {
                    method: 'POST',
                    body: postData
                });
    
                let responseData = await response.json();
                if (responseData.state === false || responseData.state === true) {
                    return responseData;
                }
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
  }
    
  async editPermissions(data){
        const name = localStorage.getItem("username");
        const password = localStorage.getItem("password");
        console.log(`${api}edit_permissions.php?name=${name}&password=${password}&data=${data}`)
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}edit_permissions.php?name=${name}&password=${password}&data=${data}`);
                let responseData = JSON.parse(await response.text());
                if (responseData.state === false || responseData.state === true) {
                    return responseData
                } 
            }catch{
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
          
        }
  }
  async addEmployee(data){
        const name = localStorage.getItem("username");
        const password = localStorage.getItem("password");
        console.log(`${api}add_employee.php?name=${name}&password=${password}&data=${data}`)
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}add_employee.php?name=${name}&password=${password}&data=${data}`);
                let responseData = JSON.parse(await response.text());
                if (responseData.state === false || responseData.state === true) {
                    return responseData
                } 
            }catch{
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        
        }
  }
  async delEmployee(data){
        const name = localStorage.getItem("username");
        const password = localStorage.getItem("password");
        console.log(`${api}del_employee.php?name=${name}&password=${password}&data=${data}`)
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}del_employee.php?name=${name}&password=${password}&data=${data}`);
                let responseData = JSON.parse(await response.text());
                if (responseData.state === false || responseData.state === true) {
                    return responseData
                } 
            }catch{
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        
        }
  }

  async createWareHouseReport(){
    const name = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    console.log(`${api}create_warehouse_report.php?name=${name}&password=${password}`)
    for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}create_warehouse_report.php?name=${name}&password=${password}`);
            let responseData = JSON.parse(await response.text());
            console.log(responseData)
            return responseData; 
        }catch{
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    
    }
}
    async editInvoice(data){
        let name = localStorage.getItem("username");
        let password = localStorage.getItem("password");
    
        let postData = new FormData();
        postData.append('name', name);
        postData.append('password', password);
        postData.append('data', data);
    
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}edit_invoice.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.json();   
                return responseData;
            } catch(error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        alert("خطأ");
}
}


function App() {
  // useEffect(() => {
  //   async function getPermissions() {
  //     for (let i = 0; i < 100; i++) {
  //       let username = localStorage.getItem("username");
  //       if (username != null || username !== undefined) {
  //         try {
  //           console.log(`${api}permissions.php?name=${username}`)
  //           let response2 = await fetch(`${api}permissions.php?username=${username}`);
  //           let responseData2 = await response2.text();
  //           if (responseData2["state"] === false) {
  //             window.location.href = "/";
  //           } else {
  //             localStorage.setItem("permissions", JSON.stringify(JSON.parse(responseData2)));
  //             return;
  //           }
  //         } catch (e){
  //           await new Promise(resolve => setTimeout(resolve, 1000));
  //         }
  //       }
  //     }
  //   }

  //   getPermissions();
  // }, []);

  return (
    <Provider store={store}>
          <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='/sellpoints' element={<SellPointPage />} />
                <Route path='/sellpoints/customer-invoice' element={<CustomerInvoicePage />} />
                <Route path='/sellpoints/customer-invoice/invoice' element={<Invoice></Invoice>}/>
                <Route path='/sellpoints/seller-invoice' element={<SellerInvoicePage />} />
                <Route path='/sellpoints/seller-invoice/invoice' element={<Invoice />} />
                <Route path='/storage' element={<StoragePage />} />
                <Route path='/storage/report' element={<WareHouseReportPage />} />

                <Route path='/invoices' element={<InvoicesPage />} />
                <Route path='/invoices/invoice' element={<Invoice></Invoice>} />
                <Route path='/merchants-and-clients' element={<MerchantsAndClientsPage></MerchantsAndClientsPage>} />
                <Route path='/merchants-and-clients/*' element={<MerchantOrClientInvoices></MerchantOrClientInvoices>} />
                <Route path='/employees' element={<EmployeesPage></EmployeesPage>} />
                <Route path='/returns' element={<ReturnsPage></ReturnsPage>} />
                <Route path='/expenses' element={<ExpensesPage/>} />
                <Route path='/orders' element={<OrdersPage/>} />
                <Route path='/reports' element={<ReportsPage/>} />
                <Route path='/welcome' element={<Welcome />} />
                <Route path='/dashboard' element={<Dashboard></Dashboard>}/>
            </Routes>
        </BrowserRouter>
    </Provider>
  
  );
}



export default App;


