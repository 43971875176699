import styles from "../../assets/css/popups/completesellpopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


 function CompleteSellerSellPopup() {
    let functions = new JSFunctions();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    let state = useSelector(state => state);
    const Toast = new Toasts();
    
    function getDate() {
        const now = new Date();
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
        const year = now.getFullYear().toString(); // Get last two digits of the year
        const hour = now.getHours().toString().padStart(2, '0');
        const minute = now.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}-${hour}-${minute}`;
        return formattedDate;
    }
    function generateCode() {
        const now = new Date();
        const day = parseInt(now.getDate(), 10).toString(16);
        const month = (parseInt(now.getMonth(), 10) + 1).toString(16);
        const year = parseInt(now.getFullYear(), 10).toString(16);
        const hours = parseInt(now.getHours(), 10).toString(16);
        const minutes = parseInt(now.getMinutes(), 10).toString(16);
        const seconds = parseInt(now.getSeconds(), 10).toString(16);
        const milliseconds = parseInt(now.getMilliseconds(), 10).toString(16);
        const code = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}-${milliseconds}`;
        return code;
    }
    function calculateProfit(products){
        let profit = 0;
        let discount = 0;
        products.forEach((ele)=>{
            console.log(ele["customerprice"] )
            profit += +ele["customerprice"] - +ele["price"];
            discount += +ele["discount"];
        })
        return profit - discount;
    }
    async function finishSell(){ 
        if (isNaN(+state.total) || isNaN(+state.paid) || state.customerName.length === 0)  {
            Toast.error("يجب إدخال الإسم والمدفوع")
        }else {
            let date;
            if (state.time.length > 0 && state.date.length > 0 ) {
                date = state.date.split("-").reverse().join("-") + "-" +state.time.split(":").join("-");
                console.log(date)
            }else if (state.time.length > 0 || state.date.length > 0) {
                alert("برجاء إكمال بيانات التاريخ");
                return;
            }else {
                date = getDate();
            }

            let data = {};
            data["name"] = state.customerName;
            data["products"] = state.products;
            data["date"] = date
            data["total"] = state.total;
            data["net"] = state.paid - state.total;
            data["phone"] = state.customerPhone;
            data["profit"] =  calculateProfit(state.products)
            data["iscustomer"] = state.isCustomer;
            data["code"] = generateCode();
            dispatch({type: "", payload: {code: data["code"],date: date, numberOfProducts: state.products.length,navigateto:"sellpoints"}})
            data = JSON.stringify(data);
            let responseData;
            if (state.isCustomer) {
                responseData = await functions.finishCustomerSell(data);
            }else {
                responseData = await functions.finishSellerSell(data);
            }
            if (responseData) {
                Toast.success("تم البيع بنجاح.");
                dispatch({type: "", payload: {sellerInvoicePopupState: false}})
                navigate("/invoices/invoice");
            }else {
                Toast.error("فشلت.");
                dispatch({type: "", payload: {sellerInvoicePopupState: false}})
                
            }
        }
        
    }
    function cancle(){
        dispatch({type: "", payload: {sellerInvoicePopupState: false}})
    }
    useEffect(()=>{
        dispatch({type: "", payload: {time: "",date: ""}})
        
    },[])
    return <div className={styles["popup"]}>
        <div className={styles.header}>
            <h2 className={styles["title"]}>إتمام عملية الشراء (تاجر)</h2>
            <div onClick={()=>cancle()}><BackBtn/></div>
        </div>
        <h4>التاريخ والوقت (سيتم إختيار الوقت الحالي في حالة عدم كتابة التاريخ والوقت)</h4>
        <div className={styles["date-time"]}>
         <input type="date" value={state.date} onChange={(ele)=>dispatch({type: "",payload: {date: ele.target.value}})}></input>
         <input type="time" value={state.time} onChange={(ele)=>dispatch({type: "",payload: {time: ele.target.value}})}></input>
        </div>

        <span className={styles["del"]}onClick={
            ()=> dispatch({type: "",payload: {date: "", time: ""}})
        }>حذف التاريخ</span>

        <h4>بيانات التاجر:</h4>
        <input className={styles["pop-up-input"]}  value={state.customerName} onChange={(ele)=>dispatch({type: "",payload: {customerName: ele.target.value}})} placeholder="الإسم"></input>
        <input className={styles["pop-up-input"]}  value={state.customerPhone} onChange={(ele)=>dispatch({type: "", payload: {customerPhone: ele.target.value}})} placeholder="رقم التيليفون"></input>
        <hr/>
       <div className={styles["gp"]}>
        <div >
            <span>المطلوب: </span>
            <input placeholder={state.total} type="number" readOnly></input>
        </div>
        <div>
            <span>المدفوع: </span>
            <input  value={state.paid} type="number" onChange={(ele)=>dispatch({type: "", payload: {paid: ele.target.value}})}></input>
        </div>
       </div>
       <div className={styles["gp"]}>
        <div>
                <span>الباقي: </span>
                <input  placeholder={+state.paid - +state.total} ></input>
            </div>
       </div>
        <button className={styles["btn"]} onClick={()=>finishSell()}>إتمام عملية البيع</button>
    </div>
}



export default CompleteSellerSellPopup;