import { JSFunctions } from "../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../assets/toasts/toasts";
import { useNavigate } from "react-router-dom";
import Bar from "../components/bars";
import Storage from "../components/storage";
import WareHouseReport from "../components/warehousereport";


function WareHouseReportPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"المخزن": "/storage"},
        {"تقرير مخزن": "/storage/report"},
      ]
        return (
          <>
            <Bar pagename="المخزن" notification={"1"} breadCrump={breadCrump} child={<WareHouseReport/>}/>
          </>
        )
}

export default WareHouseReportPage;