
import toast from 'react-hot-toast';

class Toasts {
    success = (text) => {
        toast.success(text, {
            style: {
              border: '1px solid #038346',
              padding: '16px',
              color: '#038346',
            },
            iconTheme: {
              primary: '#038346',
              secondary: '#FFFAEE',
            },
          });
    }
    error = (text) => {
        toast.error(text, {
            style: {
              border: '1px solid #F74343',
              padding: '16px',
              color: '#F74343',
            },
            iconTheme: {
              primary: '#F74343',
              secondary: '#FFFAEE',
            },
          });
    }
}

export default Toasts;