import styles from "../../assets/css/popups/showsellerinvoicepopup.module.css";
import BackBtn from "../backbtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { JSFunctions } from "../../App";

function ShowSellerInvoicePopup(props) {
  const [data, setData] = useState({
    products: [],
    history: []
  });
  const dispatch = useDispatch();
  let functions = JSFunctions;

  function cancle() {
    dispatch({ type: "", payload: { showSellerInvoiceWidgetState: false } });
  }
  async function edit(){
    let updatedData = data;
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so we add 1
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;

    const equ = 0;
    updatedData["paid"] =  +updatedData["total_price"] -  +equ ;
    if (equ === 0) {
        updatedData["state"] = "closed";
        data.addedValue = +(data["total_price"] - data["paid"]);
    }
    updatedData["last_edited_date"] = formattedDate;

    updatedData["history"].push({"date": formattedDate,"added": data.addedValue,"paid": updatedData["paid"],"net": +equ});
    updatedData = JSON.stringify(updatedData);
    console.log(updatedData);
    // Upload!!!

  }
  useEffect(()=>{
    setData(props.invoice);
    console.log(data)
  },[])
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>عرض فاتورة</h2>
          <div onClick={() => cancle()}>
            <BackBtn />
          </div>
        </div>
        
        <div className={styles["gp"]}>
            <div> 
                <span>التاريخ:</span>
                <span>{data.date}</span>
            </div>
            <div>
                <span>الإجمالي:</span>
                <span>{data.total_price}</span>
            </div>
            <div>
                <span>المدفوع:</span>
                <span>{data.paid}</span>
            </div>
            <div>
                <span>عدد المنتجات:</span>
                <span>{data.products.length}</span>
            </div>
        </div>
        <table>
        <thead>
            <tr>
                <th>الإسم</th>
                <th>الكود</th>
                <th>الموكل</th>
                <th>الضمان</th>
            </tr>
        </thead>
        <tbody>
        {
            data.products.map((ele)=> <tr>
                <td>{ele.name}</td>
                <td>{ele.serial}</td>
                <td>{ele.client}</td>
                <td>{ele.gurantee}</td>
            </tr>)
        }
        </tbody>
      </table>
      <h3>تاريخ السداد</h3>
      <table>
      <thead>
            <tr>
                <th>التاريخ</th>
                <th>القيمة المضافة</th>
                <th>إجمالي الدفوع حتي الأن</th>
                <th>الباقي</th>
            </tr>
        </thead>
        <tbody>
        {
            data.history.map((ele)=><tr>
            <td>{ele.date}</td>
            <td>{ele.added}</td>
            <td>{ele.paid}</td>
            <td>{ele.net}</td>
            </tr>)
        }
        </tbody>
      </table>
      </div>
    </>
  );
}

export default ShowSellerInvoicePopup;
