
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/css/returns.module.css"
import Overlay from "./overlay";
import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import AddExpensePopUp from "./popups/Expenses/addExpensePopup";

function Expenses(){
    const Toast = new Toasts();
    const state = useSelector(state => state);
    const [overlay, setOverlayState] = useState(false);
    const [data, setData] = useState({
        date: "",
        expenses: []
    });
    const addExpenseWidgetState = state.addExpenseWidgetState;


    const dispatch = useDispatch();
    async function search(){
        if (data.date.length !== 0 ) {
            let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.expenseSearch(data.date.split("-").reverse().join("-"));
            console.log(res)
            setOverlayState(false);
            if (res.state === "not_found") {
              Toast.error("لم يتم العثور علي مصروفات");
            }else {
              setData({...data, expenses: res})
          }
        }else {
            Toast.error("يجب إدخال التاريخ أولا")
        }
    }
    async function loadData(){
      let functions = new JSFunctions();
          setOverlayState(true);
          let res = await functions.expenseSearch('');
          console.log(res)
          setOverlayState(false);
          if (res.state === "not_found") {
            Toast.error("لم يتم العثور علي مصروفات");
          }else {
            setData({...data, expenses: res})
        }
    }
    async function delExpense(id){
      let x = window.confirm("هل أنت متأكد من رغبتك في حذف المصروف ؟");
      if (x) {
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.deleteExpense(id);
        setOverlayState(false);
        if (res.state === "Not found") {
          Toast.success("لم يتم العثور علي هذا المصروف, من الوارد أنه تم حذفه من قبل شخص أخر");
        }else {
          Toast.success("تم حذف المصروف.");
          window.location.reload()
      }
      }else {
        Toast.error("إسترجل يصحبي")
      }
        
    }
    useEffect(()=>{
      loadData();
    },[])
    return <>
    <div className={styles["returns"]}>
    <input className={styles["date-search"]} type="date" 
            value={data.date}
            onChange={(ele) => {
              setData({ ...data, 'date': ele.target.value });
            }}></input>
        <div className={styles["btns"]}>
            <button className="btn" onClick={search} >
            بحث  
            </button>
            <button className="btn" onClick={()=>{dispatch({type: "", payload: {addExpenseWidgetState: true}})}}>
             إضافة مصروف
            </button>
        </div>
        <div className="table-parent">
        <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>التاريخ</th>
                <th>الوصف</th>
                <th>قيمة المصروف</th>
            </tr>
        </thead>
        <tbody>
            {
                data.expenses.map((ele,index)=>{
                    ele = JSON.parse(ele);
                    return <>
                    <tr>
                        <td>{index + 1}</td>
                        <td>{ele["date"]}</td>
                        <td style={{width: "60%"}}>{ele["description"]}</td>
                        <td>{ele["value"]}</td>
                        <button className="del" onClick={()=>delExpense(ele["id"])}>
                            حذف
                          </button>
                    </tr>
           
                    </>  
                })
            }
        </tbody>
      </table>
      <Toaster
              position="top-left"
              reverseOrder={false}
            />
        </div>  
              {
                addExpenseWidgetState && (
                  <>
                    <Overlay />
                    <AddExpensePopUp />
                  </>
                )}
    </div>
      {overlay ? <Overlay></Overlay> : null}
    </>
}

export default Expenses;