import styles from "../../assets/css/popups/merchantsandclientspopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function AddMerchantOrClientPopup() {
  const [data, setData] = useState({
      name: "",
      type: "",
      date: "",
  });
  const [overlay, setOverlayState] = useState(false);

  let functions = new JSFunctions();
  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  let handleBtns = (state) => {
    dispatch({ type: "", payload: { [state]: true } });
  }

     function cancle() {
        dispatch({ type: "", payload: { "addMerchantOrClientWidgetState": false } });
    }
  async function add(){
    if (data.name.length === 0 || data.type.length === 0 || data.date.length === 0) {
        console.log(data)
        Toast.error("يجب إكمال البيانات أولا")
    }else {
        data.date = data.date.split("-").reverse().join("-");
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.addSeller(data);
        setOverlayState(false);
        if (res.state === "exist") {
          Toast.error("تم العثور علي شخص بنفس الإسم");
        }else if (res.state === true) {
          Toast.success("تمت الإضافة بنجاح");
        }
    }
  }

  return (
    <>
 <div className={styles["popup"]}>
      <div className={styles.header}>
        <h2 className={styles["title"]}>إضافة تاجر/موكل</h2>
        <div onClick={() => cancle()}>
          <BackBtn />
        </div>
      </div>
      <input
          className={styles["pop-up-input"]}
          value={data.name}
          onChange={(ele) => {
            setData({ ...data, name: ele.target.value });
          }
          }
          placeholder="الإسم"
       ></input>
        <div className={styles["gp"]}>
        <select onChange={(ele)=>setData({...data, "type": ele.target.value})}>
            <option value={"تاجر"}>تاجر</option>
            <option selected value={"موكل"}>موكل</option>
        </select>
        <input
          className={styles["pop-up-input"]}
          value={data.date}
          type="date"
          onChange={(ele) => {
            setData({ ...data, date: ele.target.value });
          }
          }
          placeholder="التاريخ"
       ></input>
        </div>
      <button onClick={add}>
          حفظ
        </button>
    </div>
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    {overlay? <Overlay></Overlay> : null}
    </>
  );
}

export default AddMerchantOrClientPopup;
