import styles from "../../../assets/css/popups/editinvoicepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function EditInvoicePopup() {
  const [data, setData] = useState({ code: "" });
  const [newData, setNewData] = useState({ products: [] , date: '', time: '' ,phone: '', paid: 0});
  const [returnData, setReturnData] = useState([]);
  const [delData, setDelData] = useState([]);
  const [overlay, setOverlayState] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  useEffect(() => {
    if (state.editinvoicedata) {
      const { products, ...rest } = state.editinvoicedata;
      setNewData({ ...rest , products: JSON.parse(products), date:  state.editinvoicedata["date"].split("-").slice(0,3).reverse().join("-") , time:  state.editinvoicedata["date"].split("-").slice(3).join("-"), paid:  state.editinvoicedata["total"] - state.editinvoicedata["net"]});
      setData(state.editinvoicedata);
    }
  }, [state.editinvoicedata]);

  const cancle = () => {
    dispatch({ type: "", payload: { editinvoice_code: "" } });
    dispatch({ type: "", payload: { editInvoiceWidgetState: false } });
  };

  const handlePress = (e) => {
    const condition = state.toggle ? "Enter" : "Control";
    if (e.key === condition) {
      DeleteInvoice();
    }
  };

  const handleInputEdit = (index, name, val) => {
    const updatedProducts = [...newData.products];
    updatedProducts[index][name] = val;
    setNewData({ ...newData, products: updatedProducts });
  };

  const DeleteInvoice = async () => {
    const code = data.code;
    if (code.length === 0) {
      Toast.error("يجب إدخال الكود أولا.");
    } else {
      const functions = new JSFunctions();
      setOverlayState(true);
      const res = await functions.deleteInvoice(code);
      setOverlayState(false);
      if (res.state === "Not found") {
        Toast.error("لم يتم العثور على فاتورة بهذا الكود.");
      } else {
        Toast.success("تم حذف الفاتورة.");
        window.location.reload();
      }
    }
  };

  const handleReturn = (serial) => {
    if (returnData.includes(serial)) {
        setReturnData(returnData.filter(item => item !== serial));

    }else {
        setDelData(delData.filter((item) => item !== serial));
        setReturnData([...returnData, serial]);
    }
    calculateTotal()
  };

  const handleDelete = (serial) => {
    if (delData.includes(serial)) {
        setDelData(delData.filter(item => item !== serial));

    }else {
        setReturnData(returnData.filter((item) => item !== serial));
        setDelData([...delData, serial]);
    }
    calculateTotal()
  };
  const calculateTotal = () =>{
    let total = 0;
    const filteredProducts = newData.products.filter((ele) => {
        return !returnData.includes(ele.serial) && !delData.includes(ele.serial);
    });
    filteredProducts.map((ele)=>total+= ele["customerprice"] - ele["discount"]);
    return total;
}
    const calculateProfit = (products)=> {
        let profit = 0;
        let discount = 0;
        products.forEach((ele)=>{
            console.log(ele["customerprice"] )
            profit += +ele["customerprice"] - +ele["price"];
            discount += +ele["discount"];
        })
        return profit - discount;
    }

const handleSave = async () => {
    if (newData.name.length === 0 || newData.date.length === 0 ) {
        Toast.error("يجب إدخال البينات كاملة");
        console.log(newData);
    } else {
        let total = calculateTotal(); // Calculate total before updating state
        setNewData({ ...newData, total: total });

        let returnProducts = [];
        let delProducts = [];
    
        // Populate returnProducts array
        returnData.forEach(serial => {
            const product = newData.products.find(item => item.serial === serial);
            if (product) {
                returnProducts.push(product);
            }
        });

        // Populate delProducts array
        delData.forEach(serial => {
            const product = newData.products.find(item => item.serial === serial);
            if (product) {
                delProducts.push(product);
            }
        });
    
        const filteredProducts = newData.products.filter((ele) => {
            return !returnData.includes(ele.serial) && !delData.includes(ele.serial);
        });

        const profit = calculateProfit(filteredProducts);
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.editInvoice(JSON.stringify({ ...newData,profit: profit,total: total, net: total - newData.paid , products: filteredProducts , returnProducts: returnProducts, delProducts: delProducts, date: (newData.date.split('-').reverse().join("-") + "-" + newData.time)}));
        console.log(res);
        setOverlayState(false);

        if (res.state === 0) {
            Toast.error("خطأ");
        } else {
            Toast.success("تم تعديل الفاتورة.");
            window.location.reload();
        }
    }
};


  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>تعديل فاتورة</h2>
          <div onClick={cancle}>
            <BackBtn />
          </div>
        </div>
        <div className={styles["gp"]}>
          <div className={styles["old_data"]}>
            الإسم: <span>{data.name}</span>
          </div>
          <div className={styles["old_data"]}>
            كود الفاتورة: <span>{data.code}</span>
          </div>
        </div>
        <div className={styles["divider"]}></div>
        <h3>البيانات الجديدة</h3>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={newData.name}
            onKeyDown={handlePress}
            onChange={(ele) => setNewData({ ...newData, name: ele.target.value })}
            placeholder="الإسم"
          />
           <input
            className={styles["pop-up-input"]}
            value={newData.phone}
            onKeyDown={handlePress}
            onChange={(ele) => setNewData({ ...newData, phone: ele.target.value })}
            placeholder="رقم الهاتف"
          />
          <select value={newData.iscustomer} onChange={(ele)=>setNewData({...newData, iscustomer: ele.target.value})}>
            <option value={"0"}>تاجر</option>
            <option value={"1"}>مستهلك</option>
          </select>

          <input
            type="date"
            className={styles["pop-up-input"]}
            value={newData.date}
            onKeyDown={handlePress}
            onChange={(ele) => {setNewData({ ...newData, date: ele.target.value })}}
            placeholder="التاريخ"
          />
          <input
            type="time"
            className={styles["pop-up-input"]}
            value={newData.time.split("-").join(":")}
            onKeyDown={handlePress}
            onChange={(ele) => {console.log(ele.target.value);setNewData({ ...newData, time:  ele.target.value })}}
          />
        </div>
        <div className={styles["gp"]}>
        <h4>الإجمالي</h4>
            <input
            readOnly
                type="number"
                className={styles["pop-up-input"]}
                value={newData.total}
                onKeyDown={handlePress}
                // onChange={(ele) => {setNewData({ ...newData, total:  ele.target.value })}}
            />
            <h4>المدفوع</h4>
            <input
                type="number"
                className={styles["pop-up-input"]}
                value={newData.paid}
                onKeyDown={handlePress}
                onChange={(ele) => {setNewData({ ...newData, paid:  ele.target.value })}}
            />
        </div>
        <div className={styles["products_header"]}>
          <span>no.</span>
          <span>الإسم</span>
          <span>سعر البيع</span>
          <span>الخصم</span>
          <span>الإجمالي</span>
          <span>الضمان</span>
          <span>الموكل</span>
          <span>التاريخ</span>
          <span>السيريال</span>
          <span></span>
        </div>
        <div className={styles["products"]}>
          {newData.products.map((product, index) => (
            <div key={product.serial}>
              <input value={index + 1} readOnly />
              <input value={product.name} onChange={(ele) => handleInputEdit(index, "name", ele.target.value)} />
              <input value={product.customerprice} onChange={(ele) => handleInputEdit(index, "customerprice", ele.target.value)} />
              <input value={product.discount} onChange={(ele) => handleInputEdit(index, "discount", ele.target.value)} />
              <input value={+product.customerprice - +product.discount} readOnly />
              <input value={product.gurantee} onChange={(ele) => handleInputEdit(index, "gurantee", ele.target.value)} />
              <input value={product.client} onChange={(ele) => handleInputEdit(index, "client", ele.target.value)} />
              <input value={product.date} onChange={(ele) => handleInputEdit(index, "date", ele.target.value)} />
              <input value={product.serial} onChange={(ele) => handleInputEdit(index, "serial", ele.target.value)} />
              {delData.includes(product.serial) ? (
                <button className={styles["red"]} onClick={() => handleDelete(product.serial)}>حذف</button>
              ) : (
                <button className={styles["gray"]} onClick={() => handleDelete(product.serial)}>حذف</button>
              )}
              {returnData.includes(product.serial) ? (
                <button className={styles["purple"]} onClick={() => handleReturn(product.serial)}>إرجاع</button>
              ) : (
                <button className={styles["gray"]} onClick={() => handleReturn(product.serial)}>إرجاع</button>
              )}
            </div>
          ))}
        </div>
        <button onClick={handleSave}>حفظ</button>
      </div>

      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay /> : null}
    </>
  );
}

export default EditInvoicePopup;
