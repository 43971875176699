import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { JSFunctions } from "../App";
import Overlay from "./overlay";
import { Toast } from "bootstrap";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import DeleteInvoicePopup from "./popups/Invoices/delInvoicePopup";
import styles from "../assets/css/invoices.module.css";
import EditInvoicePopup from "./popups/Invoices/editInvoicePopup";

function Invoices(){
    const navigate = useNavigate();
    const state = useSelector(state => state);
    let deleteInvoiceWidgetState =  state.deleteInvoiceWidgetState;
    let editInvoiceWidgetState =  state.editInvoiceWidgetState;


    let [bars,setBars] = useState({
        1: "enabled",
        2: "disabled",
        3: "disabled"
    });
    let [overlay, setOverlayState] = useState(false);
    let [tableData,setTableData] = useState([]);
    let [invoices,setInvoices] = useState([]);
    let [inputs,setInputs] = useState({
        date: "",
        name: "",
        code: ""
    });
    let [analysis, setAnalysis] = useState({
        numberOfInvoices: 0,
        numberOfClosedInvoices: 0,
        numberOfOpenedInvoices: 0,
        InvoicesSubtotal: 0,
        totalPaid: 0,
        net: 0
    })
    const Toast = new Toasts();
    const dispatch = useDispatch();

    let handleClick = (key)=>{
        const updatedBars = {
            1: "disabled",
            2: "disabled",
            3: "disabled",
          };
          updatedBars[key] = "enabled";
          setBars(updatedBars);
    }
    let loadTable = ()=>{
        setTableData(invoices.filter((ele)=>{
            ele = JSON.parse(ele);
            if (bars[1] === "enabled"){
               return ele;
            }
            else if ((ele["iscustomer"] === 1 && bars[2] === "enabled") || (ele["iscustomer"] === 0 && bars[3] === "enabled")) {
       
                return ele;
            }
        }))
 
    }
    let createAnalysis = ()=>{
        let analysisData = {
            numberOfInvoices: 0,
            numberOfClosedInvoices: 0,
            numberOfOpenedInvoices: 0,
            InvoicesSubtotal: 0,
            totalPaid: 0,
            net: 0
        }
        tableData.forEach((ele)=>{
            ele = JSON.parse(ele);
            console.log(ele)
            if (+ele["net"] !== 0) {
                analysisData["numberOfOpenedInvoices"]++;
            }else  {
                analysisData["numberOfClosedInvoices"]++;
            }
            analysisData["InvoicesSubtotal"] += (+ele["total"]);
            analysisData["net"]  += (+ele["net"])
        })
        analysisData["totalPaid"] = analysisData["InvoicesSubtotal"] - analysisData["net"];
        analysisData["numberOfInvoices"] = tableData.length;
        console.log(analysisData)
        setAnalysis(analysisData);
    }
    async function onLoad(){
        let functions = new JSFunctions();
         setOverlayState(true);
         let res = await functions.invoiceSearchByDate("");
         setInvoices(res);
         setOverlayState(false);
         loadTable();
    }
    async function searchByDate(){
        if (inputs.date.length === 0) {
            Toast.error("يجب إدخال التاريخ أولا")
        }else {
             let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.invoiceSearchByDate(inputs.date);
            setOverlayState(false);
            if(res.length >= 1) {
                Toast.success("تم البحث")
                setInvoices(res);
            }else {
                Toast.error("لم يتم العثور علي فواتير")
            }
        }     
    }
    async function searchByName(){
        if (inputs.name.length === 0) {
            Toast.error("يجب إدخال الإسم أولا")
        }else {
            let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.invoiceSearchByName(inputs.name);
            setOverlayState(false);
            if(res.length >= 1) {
                Toast.success("تم البحث")
                setInvoices(res);
            }else {
                Toast.error("لم يتم العثور علي فواتير")
            }
        }     
    }
    async function searchByCode(){
        if (inputs.code.length === 0) {
            Toast.error("يجب إدخال الكود أولا")
        }else {
            let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.invoiceSearchByCode(inputs.code);
            setOverlayState(false);
            if(res.length >= 1) {
                Toast.success("تم البحث")
                setInvoices(res);
            }else {
                Toast.error("لم يتم العثور علي فواتير")
            }
        }     
    }
    function showInvoice(key){
        let invoice = JSON.parse(tableData[key]);
        dispatch({ type: "", payload: {"customerName": invoice["name"],"customerPhone": invoice["phone"],"numberOfProducts": JSON.parse(invoice["products"]).length, "isCustomer": invoice["iscustomer"], "products":JSON.parse(invoice["products"]), "code": invoice["code"],"date": invoice["date"], "total": invoice["total"], "paid": invoice["total"] - invoice["net"], navigateto: "invoices"} });
        navigate("/invoices/invoice");
    }
    function editInvoice(key) {
        console.log(tableData[key])
        dispatch({ type: "", payload: {editinvoicedata: JSON.parse(tableData[key]), editInvoiceWidgetState: true} });
    }
    function delInvoice(code){
        dispatch({ type: "", payload: { deleteinvoice_code: code , deleteInvoiceWidgetState: true } });
    }
    useEffect(()=>{
        loadTable();
    },[invoices,bars]);
    useEffect(()=>{
        createAnalysis();
    },[tableData])
    useEffect(()=>{
        onLoad();
    },[])
    return <>
    <div className={styles["bars"]}>
        <button key={1} state={bars[1]} onClick={() => handleClick(1)}>الكل</button>
        <button key={2} state={bars[2]} onClick={() => handleClick(2)}>فواتير المستهلكين</button>
        <button key={3} state={bars[3]} onClick={() => handleClick(3)}>فواتير التجار</button>
    </div>
    <div className={styles["data"]}>
            <div>
                <span>عدد الفواتير: {analysis.numberOfInvoices}</span>
                <span>عدد الفواتير المفتوحة: {analysis.numberOfOpenedInvoices}</span>
                <span>عدد الفواتير المغلقة: {analysis.numberOfClosedInvoices}</span>
            </div>
            <div>
                <span>الإجمالي: {analysis.InvoicesSubtotal}</span>
                <span>المدفوع: {analysis.totalPaid}</span>
                <span>الباقي: {analysis.net}</span>
            </div>

        </div>
    <div className={styles["inputs"]}>
            <input placeholder="التاريخ" type="date" onChange={(e)=>setInputs({...inputs,date: e.target.value.split("-").reverse().join("-")})}></input>
            <button onClick={searchByDate}>بحث بالتاريخ</button>
            <input placeholder="الإسم"  onChange={(e)=>setInputs({...inputs,name: e.target.value})}></input>
            <button onClick={searchByName}
            >بحث بالإسم</button>
            <input placeholder="الكود" onChange={(e)=>setInputs({...inputs,code: e.target.value})}></input>
            <button  onClick={searchByCode}>بحث بالكود</button>
    </div>
    <button className={styles["del_invoice_btn"]} onClick={()=>dispatch({ type: "", payload: { deleteInvoiceWidgetState: true } })}>
        حذف فاتورة
    </button>
    <h2>الفواتير</h2>
    <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>الإسم</th>
                <th>رقم التيليفون</th>
                <th>النوع</th>
                <th>الإجمالي</th>
  
                <th>الحالة</th>
                <th>كود الفاتورة</th>
            </tr>
        </thead>
        <tbody>
        {tableData.map((ele,index)=>{
            ele = JSON.parse(ele)
            return <tr>
                <td className={styles["no"]}>{index+1}</td>
                <td>{ele["name"]}</td>
                <td>{ele["phone"]}</td>
                <td>
                    {
                        ele["iscustomer"] === 1? "مستهلك":"تاجر"
                    }
                </td>
                <td>
                {ele["total"]}
                </td>
                <td>
                {ele["net"] === 0? "مغلق": "مفتوح"}
                </td>
                <td className={styles["code"]}>
                    {ele["code"]}
                </td>
                <div style={{display: "flex", gap: "5px"}}>                  
                   <button onClick={()=>delInvoice(ele["code"])} className="del">
                    حذف</button>
        
                    <button style={{backgroundColor: "var(--blue)"}} onClick={()=>editInvoice(index)}>تعديل</button>
                    {ele["net"] === 0? null: <button style={{backgroundColor: "var(--purple)"}}>سداد</button>}
                    <button onClick={()=>showInvoice(index)}>
                    عرض</button>
                </div>

            </tr>
            
        })}
        </tbody>
    </table>
    {
                deleteInvoiceWidgetState && (
                  <>
                    <Overlay />
                    <DeleteInvoicePopup />
                  </>
                )}
                {
                editInvoiceWidgetState && (
                  <>
                    <Overlay />
                    <EditInvoicePopup />
                  </>
                )}
    {overlay ? <Overlay></Overlay> : null}
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    </>
}


export default Invoices;