import Bar from "../components/bars";
import CustomerInvoice from "../components/customerinvoice";


function CustomerInvoicePage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"نقاط البيع": "/sellpoints"},
        {"فاتورة مستهلك": "/sellpoints/customer-invoice"},

      ]
        return (
          <>
            <Bar pagename="نقاط البيع" notification={"1"} breadCrump={breadCrump} child={<CustomerInvoice/>}/>
          </>
        )
}


export default CustomerInvoicePage;