
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/css/reports.module.css"
import Overlay from "./overlay";
import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import AddOrderPopUp from "./popups/Orders/addOrderPopUp";
import * as XLSX from 'xlsx/xlsx.mjs';

function Reports(){
    const Toast = new Toasts();
    const state = useSelector(state => state);
    const [overlay, setOverlayState] = useState(false);
    const [data, setData] = useState({
        running_out: [],
        sold_out: [],
        from: "",
        to: "",
    });
    const [showAnalysis, setShowAnalysis] = useState(false)
        let functions = new JSFunctions();

    const addOrderWidgetState = state.addOrderWidgetState;

    const dispatch = useDispatch();
    async function search(){
        if (data.date.length !== 0 ) {
            let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.searchOrder(data.date.split("-").reverse().join("-"));
            console.log(res)
            setOverlayState(false);
            if (res.state === "not_found") {
              Toast.error("لم يتم العثور علي أوردرات");
            }else {
              setData({...data, orders: res})
          }
        }else {
            Toast.error("يجب إدخال التاريخ أولا")
        }
    }

    async function createMonthlyReport(){
        setShowAnalysis(false)
        let month = prompt("الشهر: (1-12)") || "";
        console.log(month)
        if (month === "x") return;

        while(month.length === 0) {
            month = prompt("برجاء إدخال الشهر بطريقة صحيحة");
            if (month === "x") return;
        }
        let year = prompt("السنة: ")|| "" ;
        if (year === "x") return ;
        while(year.trim().length === 0) {
            year = prompt("برجاء إدخال السنة بطريقة صحيحة") || "";
            if (year === "x") return ;
        }
        let res = await functions.createMonthlyReport({"month": month, "year": year});
        
        console.log(res)
        if (res.state === "not_found") {
            alert("لم يتم العثور علي بيانات بهذا التاريخ");
        }else {
            console.log(res)
            setData(res);
            addData(res);
        }
    }
    async function createYearlyReport(){
        let year = prompt("السنة: ") || "";
        if (year === "x") {
            return;
        }
        while(year.length === 0) {
            year = prompt("برجاء إدخال السنة بطريقة صحيحة") || "";
            if (year === "x") {
                return;
            }
        }
        let res = await functions.createYearlyReport({"year": year});
        if (res.state === "not_found") {
            alert("لم يتم العثور علي بيانات بهذا التاريخ");
        }else {
            setData(res);
            addData(res);;
        }
    }
    async function customReport(){
        if (data.from.length === 0 || data.to.length === 0) {
            Toast.error("برجاء إدخال التاريخ أولا")
        }else {
            let res = await functions.createCustomReport({"from_date": data.from.split("-").reverse().join("-"),"to_date": data.to.split("-").reverse().join("-"),});
            if (res.state === "not_found") {
                alert("لم يتم العثور علي بيانات بهذا التاريخ");
            }else {
                setData(res);
                addData(res);
            }
        }
    }
    async function addData(data){
        if (1) {
            // Create sheet "أخري" (Others)
            // var othersSheetData = {
            //     "running_out": data["running_out"].flat(),
            //     "sold_out": data["sold_out"].flat(),
            //     "total": [data["total"]],
            //     "profit": [data["profit"]],
            //     "returns": [data["returns"]],
            //     "expenses": [data["expenses"]],
            //     "salaries": [data["salaries"]]
            // };
    
            // Create workbook
            var wb = XLSX.utils.book_new();
    
            // Create sheet "products_with_data"
            try {
                var sheet1 = XLSX.utils.json_to_sheet(data["products_with_data"].flat()); // Flatten the nested arrays
                XLSX.utils.book_append_sheet(wb, sheet1, "المبيعات");
                console.log(data["products_with_data"].flat())
            } catch {
            }

        console.log(`DATA => ` + JSON.stringify(data) );
    
            var sheet2 = XLSX.utils.json_to_sheet(data["products"]);
            XLSX.utils.book_append_sheet(wb, sheet2, "الأكثر مبيعا");
    
            var sheet3 = XLSX.utils.json_to_sheet(data["running_out"].flat());
            XLSX.utils.book_append_sheet(wb, sheet3, "علي وشك الإنتهاء");
    
            var sheet4 = XLSX.utils.json_to_sheet(data["sold_out"].flat());
            XLSX.utils.book_append_sheet(wb, sheet4, "نفذت");
    
            // Save the workbook as an Excel file named "output.xlsx"
            XLSX.writeFile(wb, "output.xlsx");
        }
        // Best Selling
        let bestSellingText = "";
        for(const product of data["best-selling"]) {
            bestSellingText += product["name"] + ", "
        }
        bestSellingText = bestSellingText.slice(0,-2)

        setData({...data, 
            totalLoss: data["salaries"] + data["expenses"] +  Math.abs(data["returns"]),
            net: data["profit"] - ( data["salaries"] + data["expenses"] + Math.abs(data["returns"]) ),
            bestSelling: bestSellingText
        });
        setShowAnalysis(true)
 
    }
    return <>
    <div className={styles["reports"]}>
    <div className={styles["manual-report-inputs"]}>
            <div>
                <span>من</span>
                <input type="date" value={data.from}  onChange={(ele) => {
                    setData({ ...data, from: ele.target.value });
                }}></input>
            </div>
            <div>
                <span>إلي</span>
                <input type="date" value={data.to} onChange={(ele) => {
                    setData({ ...data, to: ele.target.value });
                }}></input>
            </div>
        </div>
        <div className={styles["btns"]}>
            <button className="btn" onClick={createMonthlyReport} >
            تقرير شهري
            </button>
            <button className="btn" onClick={createYearlyReport}>
              تقرير سنوي
            </button>
            <button className="btn" onClick={customReport}>
            تقرير مدة
            </button>
        </div>

        {
            showAnalysis?  <><div className={styles["analysis"]}>
            <div>
                <span>الأكثر مبيعا: <span className={styles["green"]}>{data["bestSelling"]}</span> </span>
            </div>
            <div>
                <span>الدخل:  <span className={styles["green"]}>{data["total"]}</span></span>
                <span>الربح:  <span className={styles["green"]}>{data["profit"]}</span></span>
            </div>
            <div>
                <span>المرتجعات:  <span className={styles["red"]}>{data["returns"]}</span></span>
                <span>المصروفات: <span className={styles["red"]}>{data["expenses"]}</span></span>
                <span>المرتبات:  <span className={styles["red"]}>{data["salaries"]}</span></span>
                <span>=  <span className={styles["red"]}>{data["totalLoss"]}</span></span>
            </div>
            <div>
                <span>صافي الربح:  <span className={styles["green"]}>{data["net"]}</span></span>
            </div>
        </div>
        <div className={styles["tables-wrap"]}>
        {data["running_out"].length === 0?   <h3>منتجات علي وشك النفاذ (لا يوجد)</h3>: 
        <div >
        <h3>منتجات علي وشك النفاذ</h3>
        <table>
            <thead>
                <tr>
                    <th>الإسم</th>
                    <th>العدد</th>
                </tr>
            </thead>
            <tbody>
                {
                    data["running_out"].map((ele)=>{
                        return <tr>
                            <td>{ele["name"]}</td>
                            <td>{ele["num"]}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        </div>
        } 
        {data["sold_out"].length === 0? <h3>منتجات نفذت (لا يوجد)</h3> : 
        <div>
        <h3>منتجات نفذت</h3>
        <table>
            <thead>
                <tr>
                    <th>الإسم</th>
                </tr>
            </thead>
            <tbody>
                {
                    data["sold_out"].map((ele)=>{
                        return <tr>
                            <td>{ele}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        </div>
        }
        </div>
       </>  : null
        }

              {
                addOrderWidgetState && (
                  <>
                    <Overlay />
                    <AddOrderPopUp/>
                  </>
                )}
    </div>
    <Toaster position="top-left" reverseOrder={false} />

      {overlay ? <Overlay></Overlay> : null}
    </>
}

export default Reports;