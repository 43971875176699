import styles from "../../../assets/css/popups/editcategorypricepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function AddExpensePopUp() {
  const [data, setData] = useState({
    description: "",
    value: "",
    date: ""
  });
  const [overlay, setOverlayState] = useState(false);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { addExpenseWidgetState: false } });
  }
  async function add(){
      if (data.date.length !==0 && data.description.length !==0  && data.value.length !==0) {
        data["date"] = data["date"].split("-").reverse().join("-");
        let functions = new JSFunctions();
        setOverlayState(true);
        let response = await functions.addExpense(data);
        setOverlayState(false);
        if (response.state === "added") {
            Toast.success("تم إضافة المصروف بنجاح");
        }else {
            Toast.error(response.state);
        }
      } else {
        Toast.error("يجب إدخال البيانات أولا")
      }
  }
  return (
  <>
        <div className={styles["popup"]}>
            <div className={styles.header}>
          <h2 className={styles["title"]}>إضافة مصروف</h2>
          <div onClick={() => cancle("addExpenseWidgetState")}>
            <BackBtn />
          </div>
            </div>
            <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.date}
            type="date"
            onChange={(ele) => {
              setData({ ...data, date: ele.target.value });
            }}
            placeholder="التاريخ"
          ></input>

            </div>
            <div className={styles["gp"]}>
                <input
                    className={styles["pop-up-input"]}
                    value={data.description}
                    onChange={(ele) => {
                    setData({ ...data, description: ele.target.value });
                    }}
                    placeholder="الوصف"
                ></input>
                                   <input
            className={styles["pop-up-input"]}
            value={data.value}
            onChange={(ele) => {
              setData({ ...data, value: ele.target.value });
            }}
            placeholder="قيمة المصروف"
          ></input>
            </div>
       <button onClick={add}>حفظ</button>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default AddExpensePopUp;
