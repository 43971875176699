import styles from "../../../assets/css/popups/addinvoicepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function HistoryPopup(props) {
  const [data, setData] = useState([]);
  const [overlay, setOverlayState] = useState(false);


  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    console.log(props);
    dispatch({ type: "", payload: { historyWidgetState: false } });
  }
  async function getEmployees(){
    let functions = new JSFunctions();
    setOverlayState(true);
    let employees = await functions.getEmployees();
    setOverlayState(false);
    employees.forEach((ele)=>{
        ele = JSON.parse(ele);
        if (ele["name"] === props.selectedEmployeeName) {
            if (JSON.parse(ele["history"]).length === 0) {
                Toast.error("لم يحصل هذا الموظف علي راتب مسبقا")
            }else {
                let history = JSON.parse(ele["history"])
                setData(history)
            }
        }
    })
  }
  useEffect(()=>{
    getEmployees();
  },[])
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>سجل تسليم الراتب ({props.selectedEmployeeName})</h2>
          <div onClick={() => cancle("historyWidgetState")}>
            <BackBtn />
          </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>التسلسل</th>
                    <th>التاريخ</th>
                    <th>الراتب</th>
                </tr>
            </thead>
            <tbody>
            {
                data.map((ele,index)=>{
                    return <tr>
                        <td>{index+1}</td>
                        <td>{ele["date"].split("-").slice(0,3).join("-")}</td>
                        <td>{ele["salary"]}</td>
                    </tr>
                })
            }
            </tbody>
        </table>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default HistoryPopup;
