import Bar from "../components/bars";
import MerchantsAndClients from "../components/merchantsandclients";

function MerchantsAndClientsPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"التجار والموكلين": "/merchants-and-clients"}
    ]
    return <Bar pagename="التجار والموكلين" breadCrump={breadCrump} child={<MerchantsAndClients/>}></Bar>
}

export default MerchantsAndClientsPage;