import styles from "../../assets/css/popups/merchantsandclientspopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function DelMerchantOrClientPopup() {
  const [data, setData] = useState({
      name: "",
  });
  const [overlay, setOverlayState] = useState(false);

  let functions = new JSFunctions();
  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  let handleBtns = (state) => {
    dispatch({ type: "", payload: { [state]: true } });
  }

     function cancle() {
        dispatch({ type: "", payload: { "delMerchantOrClientWidgetState": false } });
    }
  async function del(){
    if (data.name.length === 0) {
        Toast.error("يجب إكمال البيانات أولا")
    }else {
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.delSeller(data);
        console.log(res)
        setOverlayState(false);
        if (res.state === "not_found") {
            Toast.error("لم يتم العثور علي شخص بهذا الإسم")
        }else if (res.state === "deleted") {
            Toast.success("تم الخذف بنجاح");
        }
    }
  }

  return (
    <>
 <div className={styles["popup"]}>
      <div className={styles.header}>
        <h2 className={styles["title"]}>حذف تاجر/موكل</h2>
        <div onClick={() => cancle()}>
          <BackBtn />
        </div>
      </div>
      <input
          className={styles["pop-up-input"]}
          value={data.name}
          onChange={(ele) => {
            setData({ ...data, name: ele.target.value });
          }
          }
          placeholder="الإسم"
       ></input>
      <button onClick={del}>
          حفظ
        </button>
    </div>
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    {overlay? <Overlay></Overlay> : null}
    </>
  );
}

export default DelMerchantOrClientPopup;
