import styles from "../../../assets/css/popups/employees/permissionspopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";





function PermissionsPopup(props) {
  const [overlay, setOverlayState] = useState(false);
  const [employeeFunctionsPermissions, setEmployeeFunctionsPermissions] = useState({});
  const [employeePagesPermissions, setEmployeePagesPermissions] = useState({});


  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { permissionsWidgetState: false } });
  }
  async function uploadPermissions(){
    let data = {
        name: props.selectedEmployeeName,
        pages_permissions: employeePagesPermissions,
        functions_permissions: employeeFunctionsPermissions
    }
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.editPermissions(JSON.stringify(data));
        setOverlayState(false);
        console.log(res)
        if (!res) {
            Toast.error("حدث خطأ.");
        } else {
            Toast.success("تم تعديل التصاريح")
        }
   }
 
  async function getEmployees(){
    let functions = new JSFunctions();
    setOverlayState(true);
    let employees = await functions.getEmployees();
    setOverlayState(false);
    employees.forEach((ele,index)=>{
        ele = JSON.parse(ele);
        if (ele["name"] === props.selectedEmployeeName) {
            // console.log(JSON.stringify(ele))
            console.log(ele["functions_permissions"])
            setEmployeeFunctionsPermissions(JSON.parse(ele["functions_permissions"]))
            setEmployeePagesPermissions(JSON.parse(ele["pages_permissions"]))

        }
    })
  }
  async function changeFunctionsPermissionsState(key) {
    console.log(key)
    setEmployeeFunctionsPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions, [key]: !prevPermissions[key] };
      console.log(updatedPermissions);
      return updatedPermissions;
    });
  }
  async function changePagesPermissionsState(key) {
    console.log(key)
    setEmployeePagesPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions, [key]: !prevPermissions[key] };
      console.log(updatedPermissions);
      return updatedPermissions;
    });
  }
  function FunctionsToggle(props) {
    return (
      <div className={styles["toggle-parent"]}>
        <span>-- {props.customkey}</span>
        {employeeFunctionsPermissions[props.customkey] ? (
          <div className={styles["toggle"] + " " + styles["true"]}>
            <div className={styles["circle"] + " " + styles["true"]} onClick={() => changeFunctionsPermissionsState(props.customkey)}></div>
          </div>
        ) : (
          <div className={styles["toggle"]}>
            <div className={styles["circle"]} onClick={() => changeFunctionsPermissionsState(props.customkey)}></div>
          </div>
        )}
      </div>
    );
  }
  function PagesToggle(props) {
    return (
        <div className={styles["toggle-parent"]}>
          <span>-- {props.customkey}</span>
          {employeePagesPermissions[props.customkey] ? (
            <div className={styles["toggle"] + " " + styles["true"]}>
              <div className={styles["circle"] + " " + styles["true"]} onClick={() => changePagesPermissionsState(props.customkey)}></div>
            </div>
          ) : (
            <div className={styles["toggle"]}>
              <div className={styles["circle"]} onClick={() => changePagesPermissionsState(props.customkey)}></div>
            </div>
          )}
        </div>
      );
  }
  
  useEffect(()=>{
    getEmployees();
  },[])
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>صلاحيات ({props.selectedEmployeeName})</h2>
          <div onClick={() => cancle("giveSalaryWidgetState")}>
            <BackBtn />
          </div>
        </div>
        <h3>صلاحيات الإستخدام</h3>
        <div className={styles["permissions"]}>
            <div>
                <h4>نقطة البيع</h4>
                <FunctionsToggle customkey="بيع لمستهلكين"/>
                <FunctionsToggle customkey="بيع لتجار"/>
            </div>
            <div>
                <h4>المخزن</h4>
                <FunctionsToggle customkey="بحث بالكود"/>
                <FunctionsToggle customkey="بحث بالإسم"/>
                <FunctionsToggle customkey="بحث صنف"/>
                <FunctionsToggle customkey="تعديل سعر منتج (فردي)"/>
                <FunctionsToggle customkey="تعديل سعر صنف"/>
                <FunctionsToggle customkey="حذف منتج"/>
                <FunctionsToggle customkey="إضافة منتجات"/>
                <FunctionsToggle customkey="إخراج تقرير مخزن"/>

            </div>
            <div>
            <h4>الفواتير</h4>
        <FunctionsToggle customkey="بحث فواتير (كود)"/>
        <FunctionsToggle customkey="بحث فواتير (تاريخ)"/>
        <FunctionsToggle customkey="بحث فواتير (إسم)"/>
        <FunctionsToggle customkey="تعديل فواتير"/>
        <FunctionsToggle customkey="حذف فواتير"/>
        <FunctionsToggle customkey="سداد فواتير بيع"/>


            </div>
            <div>
            <h4>التجار والموكلين</h4>
            <FunctionsToggle customkey="معرفة التجار والموكلين"/>
            <FunctionsToggle customkey="إضافة موكل / تاجر"/>
            <FunctionsToggle customkey="حذف تاجر / موكل"/>
            <FunctionsToggle customkey="إضافة فواتير تجار"/>
            <FunctionsToggle customkey="سداد فواتير تجار"/>
            </div>
            <div>
            <h4>الموظفين</h4>
          <FunctionsToggle customkey="معرفة الموظفين"/>
          <FunctionsToggle customkey="تعديل معلومات موظفين"/>
          <FunctionsToggle customkey="إضافة موظفين"/>
          <FunctionsToggle customkey="حذف موظفين"/>
          <FunctionsToggle customkey="تغيير باسورد"/>
          <FunctionsToggle customkey="تسليم رواتب"/>
          <FunctionsToggle customkey="تعديل تصاريح"/>


            </div>
            <div>
            <h4>المرتجعات</h4>
         <FunctionsToggle customkey="بحث مرتجعات (تاريخ)"/>
         <FunctionsToggle customkey="إضافة مرتجعات"/>
        <FunctionsToggle customkey="حذف مرتجعات"/>

            </div>
            <div>
            <h4>مصروفات المحل</h4>
              <FunctionsToggle customkey="بحث مصروفات"/>
              <FunctionsToggle customkey="إضافة مصروف"/>
        <FunctionsToggle customkey="حذف مصروفات"/>

            </div>
            <div>
            <h4>الأوردرات</h4>
              <FunctionsToggle customkey="بحث أوردرات"/>
              <FunctionsToggle customkey="إضافة أوردر"/>
              <FunctionsToggle customkey="تحصيل أوردرات"/>
              <FunctionsToggle customkey="شحن أوردرات"/>
              <FunctionsToggle customkey="حذف أوردرات"/>



            </div>
            <div>
            <h4>التقارير</h4>
              <FunctionsToggle customkey="إخراج تقرير"/>
            </div>

        </div>
        <h3>صلاحيات الصفحات</h3>
        <div className={styles["permissions"]}>
            <div>
                <PagesToggle customkey="نقطة البيع"/>
                <PagesToggle customkey="المخزن"/>
                <PagesToggle customkey="الفواتير"/>
                <PagesToggle customkey="التجار والموكلين"/>
                <PagesToggle customkey="الموظفين"/>
                <PagesToggle customkey="المرتجعات"/>
                <PagesToggle customkey="مصروفات المحل"/>
                <PagesToggle customkey="الشحنات"/>
                <PagesToggle customkey="التقارير"/>


            </div>
        </div>
        

      
      <button onClick={uploadPermissions}>حفظ</button>

      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default PermissionsPopup;
