import styles from "../../assets/css/popups/editproductprice(single).module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function EditProductPricePopUp() {
  const [data, setData] = useState({
    serial: "",
    searchData: {
      customerprice: "",
      price: "",
    },
    newData: {
        name: "",
        client: "",
        customerprice: 0,
        price: 0,
        newSerial: "",
        gurantee: "",
        date: "",
        category: ""
    }
  });
  const [overlay, setOverlayState] = useState(false);
  const [note, setNoteState] = useState(true);
  const [InputsDirections, setInputsDirection] = useState({
    serialInputDirection: "rtl"
  })


  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: {"editproductprice_serial": "" } });
    dispatch({ type: "", payload: { editProductPriceWidgetState: false } });
  }
  async function searchCurrentPrice(){
    let serial = data.serial;
    if (serial.length === 0) {
        console.log(Toast)
        Toast.error("يجب إدخال السيريال أولا.")
    }else {
        let functions = new JSFunctions();
        setOverlayState(true);
        let product = await functions.searchByCode(serial);
        product = JSON.parse(product[0]);
        setOverlayState(false);
        if (!product) {
          Toast.error("لم يتم العثور علي منتج بهذا الكود.");
            setNoteState(true);
        }else {
          console.log(product)
          setData({...data, searchData: {customerprice: product.customerprice, price: product.price,name: product.name}});
          setNoteState(false);
          Toast.success("تم العثور علي المنتج.");
        }
    }
  }
  async function upload(){
    console.log(data.newData)
      if (data.newData.name.length > 0 && data.newData.price >= 0 && data.newData.customerprice >=0 && data.newData.newSerial.length > 0 && data.newData.date.length > 0 && data.newData.client.length > 0 && data.newData.gurantee.length > 0){
        let functions = new JSFunctions();
        setOverlayState(true);
        let product = await functions.editProduct(data.serial,JSON.stringify(data.newData));
        setOverlayState(false);
        if (product.state === "edited") {
            Toast.success("تم تعديل سعر المنتج")
        } else if (product.state === "Not found") {
           Toast.error("لم يتم العثور علي منتج بهذا السيريال");
        }
      } else {
        Toast.error("يجب إدخال البيانات أولا")
      }
  }
  const handleCondition = (e) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control";
    }
    if (e.key === condition) {
      searchCurrentPrice();
    }
  };
  const detectDirection = (text,x,y) => {
    const arabicRegex = /[\u0600-\u06FF]/; // Unicode range for Arabic characters
    let z =  arabicRegex.test(text) ? "right" : "left"
    if (text.length === 0) {
      z = "right"
    }
    setData({...data, [x]: text});
    setInputsDirection({...InputsDirections,[y]: z })
  };
  useEffect(()=>{
    console.log({...data, serial: state.editproductprice_serial, newData: {...data.newData, name: state.editproductprice_name,client: state.editproductprice_client,gurantee: state.editproductprice_gurantee, customerprice: state.editproductprice_customerprice, price: state.editproductprice_price , date: state.editproductprice_date }});
    if (state.editproductprice_serial) {
      setData({...data, serial: state.editproductprice_serial, newData: {...data.newData, name: state.editproductprice_name,client: state.editproductprice_client,gurantee: state.editproductprice_gurantee, customerprice: state.editproductprice_customerprice, price: state.editproductprice_price , date: state.editproductprice_date, }})
    }
  },[])
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>تعديل سعر منتج (فردي)</h2>
          <div onClick={() => cancle("addProductWidgetState")}>
            <BackBtn />
          </div>
        </div>
        <div className={styles["gp"]}>
          <input
                    style={{
          textAlign: InputsDirections.serialInputDirection,
          ...(InputsDirections.serialInputDirection === "right" ? { direction: "rtl" } : { direction: "ltr" })
        }}
            className={styles["pop-up-input"]}
            value={data.serial}
            onKeyDown={(e)=>handleCondition(e)}
            onChange={(ele) => {
              detectDirection(ele.target.value,"serial","serialInputDirection");
            }}
            placeholder="السيريال"
          ></input>
        <button onClick={searchCurrentPrice}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M27.5 15.3332C27.5 22.0526 22.0528 27.4998 15.3334 27.4998C8.61389 27.4998 3.16669 22.0526 3.16669 15.3332C3.16669 8.61371 8.61389 3.1665 15.3334 3.1665C22.0528 3.1665 27.5 8.61371 27.5 15.3332Z" fill="white" stroke="white"/>
        <path d="M28.4 28.8332C28.2856 28.8332 28.1749 28.7877 28.1002 28.713L25.6202 26.233C25.4599 26.0727 25.45 25.8002 25.6234 25.617C25.7842 25.4595 26.0548 25.451 26.2369 25.6239L28.7131 28.1001C28.8714 28.2585 28.883 28.5264 28.7161 28.7095C28.6218 28.7925 28.5071 28.8332 28.4 28.8332Z" fill="white" stroke="white"/>
      </svg>
      بحث
        </button>
        </div>
        {
            note?
              <span className={styles["note"]}>إضغط علي بحث لمعرفة سعر المنتج</span>
            : 
            <>
                <span className={styles["note"]}>إسم المنتج: <span>{data.searchData.name}</span></span>
             <span className={styles["note"]}>سعر الشراء: <span>{data.searchData.price}</span></span>
               <span className={styles["note"]}>سعر المستهلك: <span>{data.searchData.customerprice}</span></span>

            </>

              
        }

        <div className={styles["gp"]}>
          <input
          value={data.newData.name}
            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData, name: ele.target.value}});
            }}
            placeholder="الإسم"
          ></input>
        </div>
        <div className={styles["titles"]}>
            <h4>سعر المستهلك</h4>
            <h4>سعر الشراء</h4>
        </div>
        <div className={styles["gp"]}>
          <input
          value={data.newData.customerprice}
            type="number"
            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData, customerprice: +ele.target.value }});
            }}
            placeholder="سعر المستهلك"
          ></input>

          <input
            type="number"
          value={data.newData.price}
            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData, price: +ele.target.value }  });
            }}
            placeholder="سعر الشراء"
          ></input>
        </div>
        <div className={styles["gp"]}>
          <input
          value={data.newData.client}

            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData , client: ele.target.value } });
            }}
            placeholder="الموكل"
          ></input>

          <input
          value={data.newData.gurantee}

            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData, gurantee: ele.target.value } });
            }}
            placeholder="الضمان"
          ></input>

        </div>
        <div className={styles["gp"]}>
          <input
            value={data.newData.newSerial}
            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data, newData: {...data.newData, newSerial: ele.target.value }});
            }}
            placeholder="السيريال الجديد"
          ></input>

          <input
            value={data.newData.date}

            type = "date"
            className={styles["pop-up-input"]}
            onChange={(ele) => {
              setData({ ...data,  newData: {...data.newData, date: ele.target.value } });
            }}
            placeholder="التاريخ"
          ></input>

        </div>
        <div className={styles["gp"]}>
          <select
          style={{direction: "ltr"}}
            className={styles["pop-up-input"]}
            value={data.newData.category}
            onChange={(ele) => {
              setData({ ...data, newData: { ...data.newData, category: ele.target.value }});
            }}
          >
   
          <option value={"CPU"}>CPU</option>
          <option value={"GPU"}>GPU</option>
          <option value={"Motherboard"}>Motherboard</option>
          <option value={"RAM"}>RAM</option>
          <option value={"Storage"}>Storage</option>
          <option value={"CPU Cooler"}>CPU Cooler</option>
          <option value={"Case + PSU"}>Case + PSU</option>
          <option value={"Case"}>Case</option>
          <option value={"PSU"}>PSU</option>
          <option value={"Monitor"}>Monitor</option>

          <optgroup label="Accessories">
            <option value="Headphones">Headphones</option>
            <option value="Mouses">Mouses </option>
            <option value="Keyboards">Keyboards </option>
          </optgroup>

          <optgroup label="Storage">
            <option value="HDD">HDD</option>
            <option value="SSD">SSD </option>
          </optgroup>
          </select>
        </div>
       <button onClick={upload}>حفظ</button>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default EditProductPricePopUp;
