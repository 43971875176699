import Bar from "../components/bars";
import Returns from "../components/returns";

function ReturnsPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"المرتجعات": "/returns"}
    ]
    return (
        <>
           <Bar pagename="المرتجعات" notification={"1"} breadCrump={breadCrump} child={<Returns/>}/>
        </>
    )
}

export default ReturnsPage;