import styles from "../../assets/css/popups/searchbycodepopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function SearchByCodePopUp() {
  const [data, setData] = useState({
      serial: "",
      searchByCodeTable: [] ,
  });
  const [overlay, setOverlayState] = useState(false);

  let functions = new JSFunctions();
  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle(state) {
    dispatch({ type: "", payload: { [state]: false } });
  }
  async function searchByCode(){
    let serial = data.serial;
    console.log(serial.length)
    if (serial.length === 0) {

        Toast.error("يجب إدخال السيريال أولا.")
    }else {
        let functions = new JSFunctions();
        setOverlayState(true);
        let product = await functions.searchByCode(serial);
        setOverlayState(false);
        if (!product) {
          Toast.error("لم يتم العثور علي منتج بهذا الكود.");
        }else {
          setData({...data, serial: "",searchByCodeTable: product});
            console.log(product)
          Toast.success("تم العثور علي المنتج.");
         
        }
    }
  }

  const handleCondition = (e,func) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control"
    };
    if (e.key === condition) {
        console.log("hhe")
        func();
      }
    }
  return (
    <>
 <div className={styles["popup"]}>
      <div className={styles.header}>
        <h2 className={styles["title"]}>بحث بالكود</h2>
        <div onClick={() => cancle("searchByCodeWidgetState")}>
          <BackBtn />
        </div>
      </div>
      <div className={styles["gp"]}>
        <input
          className={styles["pop-up-input"]}
          value={data.serial}
          onKeyDown={(e)=>{handleCondition(e,searchByCode)}}
          onChange={(ele) => {
            setData({ ...data, serial: ele.target.value });
          }
          }
          placeholder="السيريال"
        ></input>
        <button onClick={()=>searchByCode()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M26.82 8.4118V8.41317V19.5865C26.82 21.9137 26.1282 23.7112 24.9131 24.9263C23.6979 26.1414 21.9005 26.8332 19.5733 26.8332H8.41329C6.08607 26.8332 4.2888 26.1414 3.07373 24.9248C1.8586 23.7082 1.16663 21.9074 1.16663 19.5732V8.41317C1.16663 6.08594 1.85839 4.28852 3.07351 3.07339C4.28864 1.85827 6.08606 1.1665 8.41329 1.1665H19.5866C21.9139 1.1665 23.7112 1.85831 24.9245 3.07317C26.1378 4.28793 26.8264 6.08488 26.82 8.4118ZM15.5 19.3332V15.4998H19.3333C20.1561 15.4998 20.8333 14.8226 20.8333 13.9998C20.8333 13.177 20.1561 12.4998 19.3333 12.4998H15.5V8.6665C15.5 7.84369 14.8228 7.1665 14 7.1665C13.1772 7.1665 12.5 7.8437 12.5 8.6665V12.4998H8.66663C7.84382 12.4998 7.16663 13.177 7.16663 13.9998C7.16663 14.8226 7.84382 15.4998 8.66663 15.4998H12.5V19.3332C12.5 20.156 13.1771 20.8332 14 20.8332C14.8228 20.8332 15.5 20.156 15.5 19.3332Z"
              fill="white"
              stroke="white"
            />
          </svg>
          بحث
        </button>
      </div>
      <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>الإسم</th>
                <th>الكود</th>
                <th>سعر الشراء</th>
                <th>سعر البيع</th>
                <th>الموكل</th>
                <th>الضمان</th>
                <th>التاريخ</th>
            </tr>
        </thead>
        <tbody>
            {data.searchByCodeTable.map((ele,index)=>{
              ele = JSON.parse(ele)
                return <tr>
                    <td className={styles["index"]}>{index +1}</td>
                    <td>{ele.name}</td>
                    <td>{ele.serial}</td>
                    <td>{ele.price}</td>
                    <td>{ele.customerprice}</td>
                    <td>{ele.client}</td>
                    <td>{ele.gurantee}</td>
                    <td>{ele.date}</td>
                </tr>
            })}
        </tbody>
      </table>
     
    </div>
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    {overlay? <Overlay></Overlay> : null}
    </>
  );
}

export default SearchByCodePopUp;
