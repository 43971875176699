import styles from "../../assets/css/popups/editsellerinvoicepopup.module.css";
import BackBtn from "../backbtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { JSFunctions } from "../../App";
import Toasts from "../../assets/toasts/toasts";
import Overlay from "../overlay";
import { Toaster } from "react-hot-toast";

function EditSellerInvoicePopup(props) {
  const [addedValue, setData] = useState(0);
  const dispatch = useDispatch();
  const Toast = new Toasts();
  let [overlay,setOverlayState] = useState(false);

  function cancle() {
    dispatch({ type: "", payload: { editSellerInvoiceWidgetState: false } });
  }
  async function edit(){
    if (! +addedValue >= 1) {
        Toast.error("يجب إدخال القيمة المضافة");
    }
    else if(+addedValue > (+props.invoice.data["total_price"]  - +props.invoice.data["paid"]) ){
        Toast.error("يجب إدخال قيمة لا تزيد عن المتبقي");
    }
    else {
        let updatedData = props.invoice.data;
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so we add 1
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;

        if(+addedValue === (+props.invoice.data["total_price"]  - +props.invoice.data["paid"]) ) {
            updatedData["state"] =  "closed"
        }
        updatedData["paid"] =  +updatedData["paid"]  + +addedValue;
        updatedData["last_edited_date"] = formattedDate;
        updatedData["history"].push({"date": formattedDate,"added": addedValue,"paid": updatedData["paid"],"net":  +updatedData["total_price"]  - +updatedData["paid"]});

        console.log(`Second Data = > ${JSON.stringify(updatedData)}`);
        console.log(props.invoice)
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.editSellerInvoice(props.invoice.sellername,JSON.stringify(updatedData),props.invoice.index);
        console.log(res)
        setOverlayState(false);
        if (res === false) {
          Toast.error("خطأ");
        }else if (res === true ){ 
            Toast.success("تم التعديل بنجاح")
        }

        // updatedData = JSON.stringify(updatedData);
        // Upload!!!
    }
   

  }
  useEffect(()=>{
    setData(props.invoice);
    console.log(props)
  },[])
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>سداد قيمة</h2>
          <div onClick={() => cancle()}>
            <BackBtn />
          </div>
        </div>
        <input type="number" placeholder="القيمة المضافة" onChange={(e)=>setData(e.target.value)}></input>
        <button onClick={edit}>حفظ</button>
      </div>
      {overlay ? <Overlay></Overlay> : null}
      <Toaster
              position="top-left"
              reverseOrder={false}
            />
    </>
  );
}

export default EditSellerInvoicePopup;
