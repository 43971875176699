import { useEffect, useState } from "react";
import Bar from "../components/bars";
import styles from "../assets/css/merchantsorclients.module.css"
import { JSFunctions } from "../App";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import Overlay from "../components/overlay";
import { useDispatch, useSelector } from "react-redux";
import ShowSellerInvoicePopup from "../components/popups/ShowSellerInvoicePopup";
import EditSellerInvoicePopup from "../components/popups/EditSellerInvoicePopup";
import AddInvoicePopUp from "../components/popups/AddInvoicePopup";

function MerchantOrClientInvoices(){
    const pathname = decodeURIComponent(window.location.pathname.substring(23));
    const dispatch = useDispatch();
    let [breadCrump,setBreadCrump]  = useState([]);
    let [overlay, setOverlayState] = useState([]);
    let [invoices,setInvoices] = useState([]);
    let [showInvoice,setShowInvoice]= useState([]);
    // for editing invoice
    let [invoiceData,setInvoiceData] = useState({})

    let [analysis, setAnalysis] = useState({
        numberOfInvoices: 0,
        numberOfClosedInvoices: 0,
        numberOfOpenedInvoices: 0,
        InvoicesSubtotal: 0,
        totalPaid: 0,
        net: 0
    })
    const state = useSelector(state => state);
    let showSellerInvoiceWidgetState = state.showSellerInvoiceWidgetState;
    let editSellerInvoiceWidgetState = state.editSellerInvoiceWidgetState;
    let addInvoiceWidgetState = state.addInvoiceWidgetState;


    const Toast = new Toasts();

    const onLoad  = async () =>{
        let functions = new JSFunctions();
        setOverlayState(true);
        let data =  await functions.getSellerByName(pathname);
        setOverlayState(false);
        setInvoices(JSON.parse(data[0].invoices));

        // ++++++
        const parsedInvoices = JSON.parse(data[0].invoices);
        const updatedData = parsedInvoices.reduce((acc, invoice) => {
            const isInvoiceOpened = invoice.state === "opened";
            acc.numberOfOpenedInvoices += isInvoiceOpened ? 1 : 0;
            acc.numberOfClosedInvoices += isInvoiceOpened ? 0 : 1;
            console.log((invoice.total_price))
            acc.InvoicesSubtotal += +(invoice.total_price);
            acc.totalPaid += +invoice.paid;
            return acc;
            }, {
            numberOfOpenedInvoices: 0,
            numberOfClosedInvoices: 0,
            InvoicesSubtotal: 0,
            totalPaid: 0,
            net: 0
        });
        updatedData.numberOfInvoices =  parsedInvoices.length;
        updatedData.net = +updatedData.InvoicesSubtotal - +updatedData.totalPaid;
        setAnalysis(updatedData);

    }
    const handleShowSellerInvoice = (index) => {
        setShowInvoice(invoices[index]);
        dispatch({ type: "", payload: { "showSellerInvoiceWidgetState": true } });
    }
    const handleEditSellerInvoice = (index) => {
        setInvoiceData({index: index,data: invoices[index], sellername: pathname});
        dispatch({ type: "", payload: { "editSellerInvoiceWidgetState": true } });
    }
    let handleBtns = (ele) => {
        dispatch({ type: "", payload: { [ele]: true } });
    }
    useEffect(()=>{
        setBreadCrump([
            {"الرئيسية": "/welcome"},
            {"التجار والموكلين": "/merchants-and-clients"},
            {[pathname]: `/merchants-and-clients/${pathname}`}
        ]);
        
        onLoad();
    },[])
    return <Bar pagename="التجار والموكلين" breadCrump={breadCrump} child={<>
    <div className={styles["buttons"]}>
        <button onClick={()=>handleBtns("addInvoiceWidgetState")}>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path d="M29.3198 10.4118V10.4132V21.5865C29.3198 23.9137 28.6281 25.7112 27.413 26.9263C26.1978 28.1414 24.4004 28.8332 22.0732 28.8332H10.9132C8.58595 28.8332 6.78867 28.1414 5.57361 26.9248C4.35848 25.7082 3.6665 23.9074 3.6665 21.5732V10.4132C3.6665 8.08594 4.35827 6.28852 5.57339 5.07339C6.78852 3.85827 8.58594 3.1665 10.9132 3.1665H22.0865C24.4138 3.1665 26.2111 3.85831 27.4244 5.07317C28.6376 6.28793 29.3262 8.08488 29.3198 10.4118ZM17.9998 21.3332V17.4998H21.8332C22.656 17.4998 23.3332 16.8226 23.3332 15.9998C23.3332 15.177 22.656 14.4998 21.8332 14.4998H17.9998V10.6665C17.9998 9.84369 17.3226 9.1665 16.4998 9.1665C15.677 9.1665 14.9998 9.8437 14.9998 10.6665V14.4998H11.1665C10.3437 14.4998 9.6665 15.177 9.6665 15.9998C9.6665 16.8226 10.3437 17.4998 11.1665 17.4998H14.9998V21.3332C14.9998 22.156 15.677 22.8332 16.4998 22.8332C17.3226 22.8332 17.9998 22.156 17.9998 21.3332Z" fill="#405189" stroke="#405189"/>
    </svg>
    إضافة فاتورة
        </button>

    </div>
    <div className={styles["data"]}>
            <div>
                <span>عدد الفواتير: {analysis.numberOfInvoices}</span>
                <span>عدد الفواتير المفتوحة: {analysis.numberOfOpenedInvoices}</span>
                <span>عدد الفواتير المغلقة: {analysis.numberOfClosedInvoices}</span>
            </div>
            <div>
                <span>الإجمالي: {analysis.InvoicesSubtotal}</span>
                <span>المدفوع: {analysis.totalPaid}</span>
                <span>الباقي: {analysis.net}</span>
            </div>

        </div>
        <div className={styles["parent"]}>
        <table>
        <thead>
            <tr>
                <th>تاريخ الإنشاء</th>
                <th>تاريخ أخر معاملة</th>
                <th>إجمالي الفاتورة</th>
                <th>المدفوع</th>
                <th>الباقي</th>
                <th>الحالة</th>
            </tr>
        </thead>
        <tbody>
            {
                invoices.map((ele,index)=><>
                <tr>
                    <td>{ele.date}</td>
                    <td>{ele.last_edited_date}</td>
                    <td>{ele.total_price}</td>
                    <td>{ele.paid}</td>
                    <td>{+ele.total_price - +ele.paid}</td>
                    {
                        ele.state === "closed"? <td>مغلق</td> : <td>مفتوح</td>
                    }
                    
                    {
                         ele.state === "opened"?
                                <button className={styles["edit"]} onClick={()=>handleEditSellerInvoice(index)}>تعديل</button>
                             : null
                        }
                        <button className={styles["show"]} onClick={()=>handleShowSellerInvoice(index)}>عرض</button>
                </tr>

                </> 
                )
            }
        </tbody>
    </table>
        </div>

    {
        showSellerInvoiceWidgetState && (
                  <>
                    <Overlay />
                    <ShowSellerInvoicePopup invoice={showInvoice}/>
                  </>
                )}
                {
        editSellerInvoiceWidgetState && (
                  <>
                    <Overlay />
                    <EditSellerInvoicePopup invoice={invoiceData}/>
                  </>
                )}
                {
                    addInvoiceWidgetState && (
                  <>
                    <Overlay />
                    <AddInvoicePopUp seller={pathname}/>
                  </>
                )
                }

                
        {overlay ? <Overlay></Overlay> : null}
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    </>
    }></Bar>
}

export default MerchantOrClientInvoices;