
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/css/employees.module.css"
import Overlay from "./overlay";
import SearchByNamePopUp from "./popups/SearchByNamePopup";
import DeleteProductPopUp from "./popups/DeleteProductPopup";
import AddProductPopUp from "./popups/AddProductPopup";
import EditProductPricePopUp from "./popups/EditProductPrice(SINGLE)";
import EditCategoryPricePopUp from "./popups/EditCategoryPricePopup";
import EditDataPopup from "./popups/Employees/EditDataPopup";

import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";

import Image1 from "../assets/images/employees/animoji1.png";
import Image2 from "../assets/images/employees/animoji2.png";
import Image3 from "../assets/images/employees/animoji3.png";
import Image4 from "../assets/images/employees/animoji4.png";
import Image5 from "../assets/images/employees/animoji5.png";
import ChangePasswordPopup from "./popups/Employees/ChangePassword";
import GiveSalaryPopup from "./popups/Employees/GiveSalaryPopup";
import HistoryPopup from "./popups/Employees/HistoryPopup";
import PermissionsPopup from "./popups/Employees/Permissions";
import AddEmployeeWidgetState from "./popups/Employees/AddEmployee";
import DelEmployeeWidgetState from "./popups/Employees/DelEmployee";


function Employees(){
    const Toast = new Toasts();
    const state = useSelector(state => state);
    const [overlay, setOverlayState] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
    const images = {
        0: Image1,
        1: Image2,
        2: Image3,
        3: Image4,
        4: Image5,
    }

    let editDataWidgetState =  state.editDataWidgetState;
    let changePasswordWidgetState =  state.changePasswordWidgetState;
    let giveSalaryWidgetState =  state.giveSalaryWidgetState;
    let historyWidgetState =  state.historyWidgetState;
    let permissionsWidgetState =  state.permissionsWidgetState;
    let addEmployeeWidgetState =  state.addEmployeeWidgetState;
    let delEmployeeWidgetState =  state.delEmployeeWidgetState;

    const dispatch = useDispatch();
    let handleBtns = (index,state) => {
      setSelectedEmployeeName(JSON.parse(employees[index])["name"])
      dispatch({ type: "", payload: { [state]: true } });
    }
    async function loadData(){
      let functions = new JSFunctions();
          setOverlayState(true);
          let employees = await functions.getEmployees();
          console.log(employees)
          setOverlayState(false);
          setEmployees(employees);
    }
    useEffect(()=>{
      loadData();
    },[])
    return <>
    <div className={styles["employees"]}>
        {
            employees.map((ele,index)=>{
                ele = JSON.parse(ele);
                return <div className={styles["employee"]}>
                    <div className={styles["image-cover"]}>
                        <img src={images[index]} alt="img"></img>
                    </div>
                    <span>{ele.name}</span>
                    <span>الراتب: {ele.salary}</span>
                    <span>معاد تسليم الراتب: {ele.raised_date}</span>
                    <button onClick={()=>{handleBtns(index,"editDataWidgetState")}}>تعديل المعلومات</button>
                    <div className={styles["gp"]}>
                        <button onClick={()=>{handleBtns(index,"giveSalaryWidgetState")}}>تسليم الراتب</button>
                        <button onClick={()=>{handleBtns(index,"changePasswordWidgetState")}}>تغيير الباسورد</button>
                    </div>
                    <div className={styles["gp"]}>
                        <button onClick={()=>{handleBtns(index,"permissionsWidgetState")}}>صلاحيات</button>
                        <button onClick={()=>{handleBtns(index,"historyWidgetState")}}>السجل</button>
                    </div>
                    </div>
            })
        }
    </div>
              {
                editDataWidgetState && (
                  <>
                    <Overlay />
                    <EditDataPopup selectedEmployeeName={selectedEmployeeName} />
                  </>
                )
              }
              {
                changePasswordWidgetState && (
                  <>
                    <Overlay />
                    <ChangePasswordPopup selectedEmployeeName={selectedEmployeeName}/>
                  </>
                )}
                {
                giveSalaryWidgetState && (
                  <>
                    <Overlay />
                    <GiveSalaryPopup selectedEmployeeName={selectedEmployeeName}/>
                  </>
                )}
                {
                    historyWidgetState && (
                  <>
                    <Overlay />
                    <HistoryPopup selectedEmployeeName={selectedEmployeeName}/>
                  </>
                )}
                {
                    permissionsWidgetState && (
                  <>
                    <Overlay />
                    <PermissionsPopup selectedEmployeeName={selectedEmployeeName}/>
                  </>
                )}
                {
                   addEmployeeWidgetState && (
                  <>
                    <Overlay />
                    <AddEmployeeWidgetState />
                  </>
                )}
                {
                   delEmployeeWidgetState && (
                  <>
                    <Overlay />
                    <DelEmployeeWidgetState />
                  </>
                )}
                <div className={styles["btns"]}>
                    <button onClick={()=>dispatch({ type: "", payload: { "addEmployeeWidgetState": true } })}>إضافة موظف</button>
                    <button onClick={()=>dispatch({ type: "", payload: { "delEmployeeWidgetState": true } })}>حذف موظف</button>
                </div>
      {overlay ? <Overlay></Overlay> : null}
    </>
}

export default Employees;