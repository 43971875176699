import styles from "../../assets/css/popups/editcategorypricepopup.module.css";
import BackBtn from "../backbtn";
import { JSFunctions } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../overlay";

function EditCategoryPricePopUp() {
  const [data, setData] = useState({
    serial: "",
    name: "",
    client: "",
    gurantee: "",
    date: "",
    category: "",
    searchdata: {
        customerprice: 0,
        price: 0
    }
  });
  const [overlay, setOverlayState] = useState(false);
  const [note, setNoteState] = useState(true);


  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { editCategoryPriceWidgetState: false } });
  }
  async function searchCurrentPrice(){
    if (data.name.length !== 0 &&  data.client.length !== 0 && data.gurantee.length !==0) {
        let functions = new JSFunctions();
        setOverlayState(true);
        let response = await functions.searchCategory({name: data.name,client: data.client, gurantee: data.gurantee, date: data.date});
        setOverlayState(false);
        if (!response) {
            Toast.error("لم يتم العثور علي الصنف")
            setNoteState(true);
        }else {
          let product = JSON.parse(response[0]);
          let length = response.length;
          setData({...data, searchdata: {name: product.name,price: product.price,customerprice: product.customerprice,length: length}});
          setNoteState(false);
          Toast.success("تم العثور علي الصنف.");
        }
    }else {

        Toast.error("يجب إدخال البيانات أولا.")

    }
  }
  async function upload(){
      if (data.name.length !== 0 && data.price >= 0 && data.customerprice >=0 &&data.client.length !== 0 && data.gurantee.length !==0) {
        let functions = new JSFunctions();
        setOverlayState(true);
        let response = await functions.editCategoryPrice(data.name,data.price,data.customerprice,data.gurantee,data.client,data.date,data.category);
        setOverlayState(false);
        if (response.state === "Not found") {
            Toast.error("لم يتم التعديل برجاء التأكد من البيانات وأن السعر الشراء والمستهلك ليس السعر الحالي");
        } else if (!response) {
            Toast.error("خطأ");
        }else {
            Toast.success(`تم تعدي سعر ${response.num} منجات`)
        }
      } else {
        Toast.error("يجب إدخال البيانات أولا")
      }
  }
  const handleCondition = (e) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control";
    }
    if (e.key === condition) {
      searchCurrentPrice();
    }
  };
  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>تعديل سعر منتج (كمية)</h2>
          <div onClick={() => cancle("addProductWidgetState")}>
            <BackBtn />
          </div>
        </div>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.name}
            onKeyDown={(e)=>handleCondition(e)}
            onChange={(ele) => {
              setData({ ...data, name: ele.target.value });
            }}
            placeholder="الإسم"
          ></input>
                   <input
            className={styles["pop-up-input"]}
            value={data.client}
            onKeyDown={(e)=>handleCondition(e)}
            onChange={(ele) => {
              setData({ ...data, client: ele.target.value });
            }}
            placeholder="الموكل"
          ></input>
        </div>
        <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.gurantee}
            onChange={(ele) => {
              setData({ ...data, gurantee: ele.target.value });
            }}
            placeholder="الضمان"
          ></input>
                   <input
            className={styles["pop-up-input"]}
            type="date"
            value={data.date}
            onChange={(ele) => {
              setData({ ...data, date: ele.target.value });
            }}
            placeholder="التاريخ"
          ></input>
          
        </div>
        {
            note?
              <span className={styles["note"]}>إضغط علي بحث لمعرفة سعر الصنف</span>
            : 
            <>
                <span className={styles["note"]}>إسم المنتج: <span>{data.searchdata.name}</span></span>
             <span className={styles["note"]}>سعر الشراء: <span>{data.searchdata.price}</span></span>
               <span className={styles["note"]}>سعر المستهلك: <span>{data.searchdata.customerprice}</span></span>
               <span className={styles["note"]}>العدد المتوفر فالمخزن: <span>{data.searchdata.length}</span></span>


            </>

              
        }
        <button className={styles["searchbtn"]} onClick={searchCurrentPrice}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M27.5 15.3332C27.5 22.0526 22.0528 27.4998 15.3334 27.4998C8.61389 27.4998 3.16669 22.0526 3.16669 15.3332C3.16669 8.61371 8.61389 3.1665 15.3334 3.1665C22.0528 3.1665 27.5 8.61371 27.5 15.3332Z" fill="white" stroke="white"/>
        <path d="M28.4 28.8332C28.2856 28.8332 28.1749 28.7877 28.1002 28.713L25.6202 26.233C25.4599 26.0727 25.45 25.8002 25.6234 25.617C25.7842 25.4595 26.0548 25.451 26.2369 25.6239L28.7131 28.1001C28.8714 28.2585 28.883 28.5264 28.7161 28.7095C28.6218 28.7925 28.5071 28.8332 28.4 28.8332Z" fill="white" stroke="white"/>
      </svg>
      بحث
        </button>
        <div className={styles["gp"]}>
          <input
            type="number"
            className={styles["pop-up-input"]}
            value={data.customerprice}
            onChange={(ele) => {
              setData({ ...data, customerprice: ele.target.value });
            }}
            placeholder="سعر المستهلك"
          ></input>

          <input
            type="number"
            className={styles["pop-up-input"]}
            value={data.price}
            onChange={(ele) => {
              setData({ ...data, price: ele.target.value });
            }}
            placeholder="سعر الشراء"
          ></input>
        </div>
        <div className={styles["gp"]}>
          <select
          style={{direction: "ltr"}}
            className={styles["pop-up-input"]}
            value={data.category}
            onChange={(ele) => {
              setData({ ...data, category: ele.target.value });            
              }}
          >
   
          <option value={"CPU"}>CPU</option>
          <option value={"GPU"}>GPU</option>
          <option value={"Motherboard"}>Motherboard</option>
          <option value={"RAM"}>RAM</option>
          <option value={"Storage"}>Storage</option>
          <option value={"CPU Cooler"}>CPU Cooler</option>
          <option value={"Case + PSU"}>Case + PSU</option>
          <option value={"Case"}>Case</option>
          <option value={"PSU"}>PSU</option>
          <option value={"Monitor"}>Monitor</option>

          <optgroup label="Accessories">
            <option value="Headphones">Headphones</option>
            <option value="Mouses">Mouses </option>
            <option value="Keyboards">Keyboards </option>
          </optgroup>

          <optgroup label="Storage">
            <option value="HDD">HDD</option>
            <option value="SSD">SSD </option>
          </optgroup>
          </select>
        </div>
       
       <button onClick={upload}>حفظ</button>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default EditCategoryPricePopUp;
