import "../assets/css/root.css"
import styles from "../assets/css/merchantsandclients.module.css";
import { useEffect, useState } from "react";
import { JSFunctions } from "../App";
import Overlay from "./overlay";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import AddMerchantOrClientPopup from "./popups/AddMerchantOrClientPopup";
import DelMerchantOrClientPopup from "./popups/DeleteMerchantOrClientPopup";
import { useNavigate } from "react-router-dom";

function MerchantsAndClients(){
    const state = useSelector(state => state);
    let [overlay, setOverlayState] = useState(false);
    let [clients, setClients] = useState([]);
    let navigate = useNavigate()
    const Toast = new Toasts();
    const dispatch = useDispatch();
    let addMerchantOrClientWidgetState =  state.addMerchantOrClientWidgetState;
    let delMerchantOrClientWidgetState =  state.delMerchantOrClientWidgetState;

    let handleBtns = (ele) => {
        dispatch({ type: "", payload: { [ele]: true } });
    }
    let onLoad  = async () =>{
        let functions = new JSFunctions();
        setOverlayState(true);
        let clients = await functions.getSellers();
        console.log(clients)
        setOverlayState(false);
        if (clients.state ==="Empty") {
          Toast.error("لم يتم العثور علي موكلين أو تجار");
        } else {
          setClients(clients)
      }
    }
    useEffect(()=>{
        onLoad();
    },[])
    return <>
    <div className={styles["actions"]}>
        <div className={styles["b1"] + " " + styles["btn"]} onClick={()=>{handleBtns("addMerchantOrClientWidgetState")}}>إضافة تاجر / موكل</div>
        <div className={styles["b2"] + " " + styles["btn"]} onClick={()=>{handleBtns("delMerchantOrClientWidgetState")}}>حذف</div>
    </div>
    <h2>التجار والموكلين ({clients.length})</h2>
    <div className={styles["parent"]}>
        {
            clients.map((ele)=>{
                return <div onClick={()=>navigate("/merchants-and-clients/" + ele.name)}>
                    <h3>{ele.name}</h3>
                    <span>{ele.type}</span>
                    <span>{ele.created_date}</span>
                </div>
            })
        }
    </div>
    {
        addMerchantOrClientWidgetState && (
                  <>
                    <Overlay />
                    <AddMerchantOrClientPopup />
                  </>
                )}
                {
                    delMerchantOrClientWidgetState&& (
                  <>
                    <Overlay />
                    <DelMerchantOrClientPopup />
                  </>
                )}
                
    {overlay ? <Overlay></Overlay> : null}
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    </>
}


export default MerchantsAndClients;