import Bar from "../components/bars";
import SellerInvoice from "../components/seller-invoice";


function SellerInvoicePage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"نقاط البيع": "/sellpoints"},
        {"فاتورة تاجر": "/sellpoints/seller-invoice"},

    ]
    return (
        <>
           <Bar pagename="نقاط البيع" notification={"1"} breadCrump={breadCrump} child={<SellerInvoice/>}/>
        </>
    )
}

export default SellerInvoicePage;