import "../assets/css/root.css";
import "../assets/css/sidebar.css";
import Logo from "../assets/images/puzzle.png";
import Cart from "../assets/images/icons/cart.svg";
import Orders from "../assets/images/icons/orders.svg";
import Storage from "../assets/images/icons/storage.svg";
import Expenses from "../assets/images/icons/expenses.svg";
import Invoices from "../assets/images/icons/expenses.svg";
import Returns from "../assets/images/icons/returns.svg";
import Reports from "../assets/images/icons/report.svg";
import Employees from "../assets/images/icons/employees.svg";
import MerchantsAndClients from "../assets/images/icons/merchantsandclients.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function Bar(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const breadCrumbElements = props.breadCrump.map((ele, index) => {
      // Use map to create an array of elements
      return Object.keys(ele).map((key, innerIndex) => 
      <>
        <span className="crump" onClick={() => navigate(ele[key])}>
          {key}
        </span>
        <span>{'>'}</span>
      </>

      );
    });
  const dispatch = useDispatch();
  const state = useSelector(state => state);


  let permissions = JSON.parse(
    JSON.parse(localStorage.getItem("permissions"))["pages_permissions"]
  );
  // console.log(localStorage.getItem("permissions"));
  let sideBar = [];
  let links = [];

  const images = {
    'نقطة البيع': <img src={Cart} alt="img" />,
    'الشحنات': <img src={Orders} alt="img" />,
    'المخزن': <img src={Storage} alt="img" />,
    'تقرير مخزن': <img src={Storage} alt="img" />,
    'مصروفات المحل': <img src={Expenses} alt="img" />,
    'الفواتير': <img src={Invoices} alt="img" />,
    'المرتجعات': <img src={Returns} alt="img" />,
    'التقارير': <img src={Reports} alt="img" />,
    'الموظفين': <img src={Employees} alt="img" />,
    'التجار والموكلين': <img src={MerchantsAndClients} alt="img" />,
  };
  const pagesInEn = {
    "نقطة البيع": "sellpoints",
    "الشحنات": "orders",
    "المخزن": "storage",
    'تقرير مخزن':"storage",
    'مصروفات المحل': "expenses",
    'الفواتير': "invoices",
    'المرتجعات': "returns",
    'التقارير': "reports",
    'الموظفين': "employees",
    'التجار والموكلين': "merchants-and-clients",
  }
  for (var key in permissions) {
    if (permissions.hasOwnProperty(key) && permissions[key]) {
      sideBar.push(key);
    }
  }
  links = sideBar.map((ele) => {
    if (sideBar.includes(ele)) {
      let classname = "link";
      if (props.pagename === ele) classname = "link focused";
      // console.log(ele);
      return (
        <div
          className={classname}
          key={ele}
          onClick={() => navigate("/" + pagesInEn[ele])}
        >
          {images[ele]}
          <span>{ele}</span>
        </div>
      );
    }
  
    // Add a default return for cases where the condition is not met
    return null;
  });
  function handleToggle(){
    dispatch({type: "",payload: {toggle: !state.toggle}})
  }
  function logOut (){
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    navigate("/")

  }
  return (
    <div className= {`bar ${show? "hide-sidebar": ""}`}>
      <div className={`sidebar case1 ${show? "hide-sidebar": ""}`}>
        <img src={Logo} alt="logo" className="logo" />
        <div className="links">
          {
            links
          }
        </div>
      </div>
      <div className="topbar">
        <div onClick={()=>setShow(!show)} className={`menu ${show? "hide-sidebar": ""}`}>
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
        <div className="btns">
        <div className="btn" onClick={()=>handleToggle()}>
            {state.toggle?<span>Auto</span> : <span>M</span> } 
          </div>
          <div className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M18.5333 25.2134C18.84 25.1867 19.1467 25.1601 19.44 25.1201C19.68 25.0934 19.92 25.0667 20.16 25.0267C20.3067 25.0134 20.4533 24.9867 20.5999 24.9601L18.5333 25.2134ZM7.06855 16.4843L7.06858 16.4843L7.07151 16.4793C7.26168 16.1545 7.42911 15.709 7.54874 15.2744C7.66825 14.8403 7.75334 14.369 7.75334 13.9867V11.5067C7.75334 6.94436 11.4615 3.24635 16.0274 3.2334C20.5771 3.23377 24.2867 6.94261 24.2867 11.5067V14.0001C24.2867 14.3825 24.3718 14.8542 24.4934 15.2896C24.6148 15.7241 24.7863 16.1716 24.9855 16.4988C24.9858 16.4993 24.9861 16.4999 24.9864 16.5004L26.4902 19.0021C26.4904 19.0025 26.4906 19.0029 26.4908 19.0032C26.961 19.7952 27.0506 20.7296 26.7321 21.5839C26.4112 22.4313 25.7371 23.0942 24.8545 23.3926C23.4382 23.8647 21.9848 24.2311 20.5207 24.4664L20.5207 24.4663L20.5106 24.4681C20.36 24.4955 20.2354 24.5178 20.1147 24.5288L20.0962 24.5305L20.0778 24.5335C19.8535 24.5709 19.627 24.5962 19.3848 24.6231L19.3848 24.623L19.3725 24.6247C19.0933 24.6627 18.7991 24.6884 18.49 24.7153L18.49 24.7153L18.4852 24.7157C17.6748 24.7941 16.8509 24.8334 16.0267 24.8334C15.1889 24.8334 14.3514 24.7941 13.5274 24.7157L13.5274 24.7155L13.5169 24.7148C13.1704 24.6891 12.8367 24.6506 12.5027 24.5992L12.5027 24.5991L12.4928 24.5978C12.2955 24.5715 12.1011 24.5456 11.9197 24.5197C11.8388 24.5053 11.7599 24.4945 11.692 24.4853L11.6876 24.4846C11.6125 24.4744 11.5501 24.4658 11.4894 24.4548L11.4895 24.4548L11.4844 24.4539C10.0256 24.2042 8.59734 23.8632 7.18479 23.3924L7.18122 23.3912C6.25184 23.0892 5.55963 22.4219 5.25563 21.5999L5.25484 21.5978C4.95524 20.7989 5.05634 19.8504 5.56307 18.989C5.56343 18.9884 5.56379 18.9878 5.56415 18.9872L7.06855 16.4843ZM15.9867 14.8467C16.8228 14.8467 17.5 14.1695 17.5 13.3334V9.20007C17.5 8.36392 16.8228 7.68673 15.9867 7.68673C15.1505 7.68673 14.4733 8.36392 14.4733 9.20007V13.3334C14.4733 14.1695 15.1505 14.8467 15.9867 14.8467Z" fill="#405189" stroke="#405189"/>
              <path d="M13.0927 27.2776C13.2839 27.3006 13.4802 27.3218 13.6774 27.3383C14.4489 27.4059 15.2367 27.4467 16.0267 27.4467C16.804 27.4467 17.579 27.4058 18.3378 27.3381L18.3378 27.3381L18.3407 27.3378C18.4153 27.3307 18.497 27.3242 18.5825 27.3173C18.6863 27.309 18.7957 27.3003 18.9049 27.2895C18.2715 28.2218 17.2047 28.8334 16 28.8334C15.0777 28.8334 14.1691 28.4586 13.5331 27.7996L13.5244 27.7905L13.5153 27.782C13.3581 27.6346 13.2163 27.4637 13.0927 27.2776Z" fill="#405189" stroke="#405189"/>
            </svg>
            <span className="notification">{props.notification}</span>
          </div>
          <div className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M28.2765 21.4949L28.2763 21.495L28.2835 21.5064C28.35 21.6119 28.4305 21.7966 28.4142 22.074C28.3977 22.3543 28.2797 22.7711 27.8757 23.3324C25.3305 26.7889 21.2945 28.82 16.9866 28.82C16.7915 28.82 16.5938 28.8078 16.3778 28.7943L16.3778 28.7942L16.3666 28.7937C15.0838 28.7424 13.8391 28.4987 12.6576 28.0877L12.6569 28.0875C7.71382 26.3755 3.99043 21.8324 3.60541 16.5382C3.22058 10.9002 6.46946 5.63249 11.726 3.42136C12.3645 3.15653 12.799 3.13949 13.079 3.19029C13.3568 3.24069 13.525 3.36547 13.6198 3.46021C13.7062 3.5467 13.8246 3.70692 13.8708 3.97103C13.9171 4.23635 13.898 4.64973 13.6349 5.25363L13.6344 5.25477C13.0063 6.70642 12.7 8.24091 12.7 9.82666L12.7 9.82911C12.714 12.6802 13.8321 15.3159 15.6406 17.3333C17.686 19.6288 20.6236 21.1198 23.8707 21.2595C24.7524 21.3014 25.6296 21.2312 26.4873 21.079L26.4879 21.0789C27.1639 20.9582 27.5859 21.0334 27.8407 21.1397C28.0928 21.245 28.2178 21.3958 28.2765 21.4949Z" fill="#405189" stroke="#405189"/>
            </svg>
          </div>
          <div className="log-out" onClick={logOut}>
            <span>خروج</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M11.8666 10.0801C12.28 5.28007 14.7466 3.32007 20.1466 3.32007H20.32C26.28 3.32007 28.6666 5.70674 28.6666 11.6667V20.3601C28.6666 26.3201 26.28 28.7067 20.32 28.7067H20.1466C14.7866 28.7067 12.32 26.7734 11.88 22.0534" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M20 16H4.82666" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.79998 11.5334L3.33331 16.0001L7.79998 20.4668" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
          </div>
        </div>
      </div>
      <div className="content">
        <span className="bread-crump">{breadCrumbElements}</span>
        {props.child}
      </div>
    </div>
  );
}

export default Bar;
