import { useSelector } from 'react-redux';
import invoiceStyles from '../assets/css/invoice.module.css';
import Barcode from "react-barcode";
import { useNavigate } from 'react-router-dom';

function Invoice(){
  const navigate = useNavigate()
  const state = useSelector(state => state);
  return state["customerName"].length !== 0 ?   
      <div className={invoiceStyles._invoice}>
    <div className={invoiceStyles.buttons}>
      <div className={invoiceStyles["return"]} onClick={()=>navigate(`/${state.navigateto}/`)}>رجوع</div>
      <div className={invoiceStyles["print-receipt"]} onClick={()=>window.print()}>طباعة</div>
    </div>
    <section className={invoiceStyles["user-data"]}>
      <main>
        <h2>ورقة ضمان - Warranty Paper</h2>
        <div id="customer-name">
          العميل: <span />{state.customerName}
        </div>
        <div id="customer-phone">
          رقم الهاتف: <span />{state.customerPhone}
        </div>
      </main>
    </section>
    <table className={invoiceStyles["invoice-data"] + " " +invoiceStyles["table"]}>
          <thead>
            <tr>
              <td>كود الطلب:</td>
              <td>التاريخ:</td>
              <td>عدد المنتجات:</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{state.code}</td>
              <td>{state.date.split("-").slice(3).join(":")} {state.date.split("-").slice(0,3).join("-")} </td>
              <td>{state.numberOfProducts}</td>
            </tr>
          </tbody>
    </table>
    <table className={invoiceStyles["table"] + " " + invoiceStyles["products"]}>
      <thead>
        <tr>
          <td>No.</td>
          <td>الوصف</td>
          <td>
            السعر
            <br />
            <span className="light">جنيه</span>
          </td>
          <td>الخصم</td>
          <td>الإجمالي</td>
          <td className={invoiceStyles["hide"]}>الضمان</td>
          <td className={invoiceStyles["hide"]}>الموكل</td>
          <td className={invoiceStyles["hide"]}>تاريخ شراء المنتج</td>
          <td>السيريال</td>
        </tr>
      </thead>
      <tbody>
      { 
              state.products.map((product,index)=>{
                return <tr key={product["serial"]}>
                <td>{index+1}</td>
                <td>{product["name"]}</td>
                
                <td>{product["customerprice"]}</td>
                <td>{product["discount"]}</td>
                <td>{+product["customerprice"] - +product["discount"]}</td>
                <td  className={invoiceStyles["hide"]}>{product["gurantee"] }</td>
                <td  className={invoiceStyles["hide"]}>{product["client"] }</td>
                <td  className={invoiceStyles["hide"]}>{product["date"] }</td>
                <td>{product["serial"]}</td>
               </tr>
              }) }
      </tbody>
    </table>
    <table className={invoiceStyles["table"] + " " +invoiceStyles["pay"]}>
      <thead>
        <tr>
          <td>المجموع بالجنيه</td>
          <td>المدفوع</td>
          <td>الباقي</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{state.total}</td>
          <td>{state.paid}</td>
          <td>{ state.total - state.paid }</td>
        </tr>
      </tbody>
    </table>
    <Barcode value={state.code} width={1} height={20} textAlign={"center"} fontSize={10} className="barcode"/>
    <section className={invoiceStyles["warranty-terms"]}>
      <h2 style={{fontSize: 18}}>شروط الضمان</h2>
      <p>
        1- فحص واستلام السلعة جيدا قبل مغادرة المحل المسئولية تقع علي العميل او
        المندوب لاستلامها
        <br />
        2- شرط اساسي رجوع السلعه في نفس حالة البيع بدون خدش او كسر او قطع او حرق
        رجوع المنتج بنفس التغليف الخارجي والداخلي بدون قطع او كتابه علي العلبه او
        بقع او سوائل (بنفس حالة الاستلام).
        <br />
        3- عند وجود مشكلة في جهاز العميل مع عدم وجود مشكلة في المنتج لا يمكن
        ارجاعها او استبدالها ومسئولية الصيانة تقع علي عاتق العميل
        <br />
          {" "}
          4- ضمان استبدال في حالة توافر نفس المنتج وفي حالة عدم توفره يتم
          الاسترجاع ورد&nbsp;قيمة&nbsp;المنتج.
      </p>
    </section>
    
     </div> :   
     <div className={invoiceStyles._invoice + " " + invoiceStyles.is_showingInvoice}>
     <div className={invoiceStyles.buttons}>
      <div className={invoiceStyles["return"]} onClick={ state.isCustomer ? ()=>navigate("/sellpoints/customer-invoice") : ()=>navigate("/sellpoints/seller-invoice")}>رجوع</div>
      <div className={invoiceStyles["print-receipt"]} onClick={()=>window.print()}>طباعة</div>
    </div>
      <table className={invoiceStyles["table"] + " " + invoiceStyles["products"]}>
        <thead>
          <tr>
            <td>No.</td>
            <td>الوصف</td>
            <td>
              السعر
              <br />
              <span className="light">جنيه</span>
            </td>
            <td>الخصم</td>
            <td>الإجمالي</td>
            <td className={invoiceStyles["hide"]}>الضمان</td>
            <td className={invoiceStyles["hide"]}>الموكل</td>
            <td className={invoiceStyles["hide"]}>تاريخ شراء المنتج</td>
            <td>السيريال</td>
          </tr>
        </thead>
        <tbody>
          { 
              state.products.map((product,index)=>{
                return <tr key={product["serial"]}>
                <td>{index+1}</td>
                <td>{product["name"]}</td>
                
                <td>{product["customerprice"]}</td>
                <td>{product["discount"]}</td>
                <td>{+product["customerprice"] - +product["discount"]}</td>
                <td  className={invoiceStyles["hide"]}>{product["gurantee"] }</td>
                <td  className={invoiceStyles["hide"]}>{product["client"] }</td>
                <td  className={invoiceStyles["hide"]}>{product["date"] }</td>
                <td>{product["serial"]}</td>
                
               </tr>
              })
          }
        </tbody>
      </table>  
     </div>
    
}
export default Invoice;