
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/css/returns.module.css"
import Overlay from "./overlay";
import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";
import { Toaster } from "react-hot-toast";
import AddOrderPopUp from "./popups/Orders/addOrderPopUp";

function Orders(){
    const Toast = new Toasts();
    const state = useSelector(state => state);
    const [overlay, setOverlayState] = useState(false);
    const [data, setData] = useState({
        date: "",
        orders: []
    });
    const addOrderWidgetState = state.addOrderWidgetState;

    const dispatch = useDispatch();
    async function search(){
        if (data.date.length !== 0 ) {
            let functions = new JSFunctions();
            setOverlayState(true);
            let res = await functions.searchOrder(data.date.split("-").reverse().join("-"));
            console.log(res)
            setOverlayState(false);
            if (res.state === "not_found") {
              Toast.error("لم يتم العثور علي أوردرات");
            }else {
              setData({...data, orders: res})
          }
        }else {
            Toast.error("يجب إدخال التاريخ أولا")
        }
    }
    async function loadData(){
      let functions = new JSFunctions();
          setOverlayState(true);
          let res = await functions.searchOrder("");
          console.log(res)
          setOverlayState(false);
          if (res.state === "not_found") {
            Toast.error("لم يتم العثور علي أوردرات");
          }else {
            console.log(res)
            setData({...data, orders: res})
        }
    }
    async function collect(id){
      var result = window.confirm("هل أنت متأكد من تحصيل الشحنة ؟ ");
      if (result) {
        setOverlayState(true);
        let functions = new JSFunctions();
        let res = await functions.collectOrder(id);
        console.log(res)
        setOverlayState(false);
        if (res.state === "not_found") {
          Toast.error("لم يتم العثور علي الأوردر");
        }else {
          Toast.success("تم تحصيل الأوردر");
          window.location.reload();
        }
      } else {
        Toast.error("طالما مش متأكد بتدوس ليهههههه!")
      }
    }
    async function deliver(id){
      var result = window.confirm("هل أنت متأكد من وصول الشحنة ؟ ");
      if (result) {
        setOverlayState(true);
        let functions = new JSFunctions();
        let res = await functions.deliverOrder(id);
        setOverlayState(false);
        if (res.state === "not_found") {
          Toast.error("لم يتم العثور علي الأوردر");
        }else {
          Toast.success("تم شحن الأوردر");
          window.location.reload();
        }
      } else {
        Toast.error("إنت حر يسيدي")
      }
    }
    async function deleteOrder(id){
      var result = window.confirm("هل أنت متأكد من رغبتك في حذف الشحنة ؟");
      if (result) {
        setOverlayState(true);
        let functions = new JSFunctions();
        let res = await functions.deleteOrder(id);
        setOverlayState(false);
        if (res.state === "not_found") {
          Toast.error("لم يتم العثور علي الأوردر");
        }else {
          Toast.success("تم حذف الأوردر");
          window.location.reload();
        }
      } else {
        Toast.error("إنت حر يسيدي")
      }
    }
    useEffect(()=>{
      loadData();
    },[])
    return <>
    <div className={styles["returns"]}>
    <input className={styles["date-search"]} type="date" 
            value={data.date}
            onChange={(ele) => {
              setData({ ...data, 'date': ele.target.value });
            }}></input>
        <div className={styles["btns"]}>
            <button className="btn" onClick={search} >
            بحث  
            </button>
            <button className="btn" onClick={()=>{dispatch({type: "", payload: {addOrderWidgetState: true}})}}>
             إضافة أوردر
            </button>
        </div>
        <div className="table-parent">
        <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>التاريخ</th>
                <th>كود الفاتورة</th>
                <th>رقم البوليصة</th>
                <th>مبلغ التحصيل</th>
                <th>مبلغ الشحن</th>
            </tr>
        </thead>
        <tbody>
            {
                data.orders.map((ele,index)=>{
                    return <tr>
                        <td>{index + 1}</td>
                        <td>{ele["date"]}</td>
                        <td>{ele["invoice_code"]}</td>
                        <td>{ele["policy_number"]}</td>
                        <td>{ele["collection_amount"]}</td>
                        <td>{ele["shipping_amount"]}</td>
                        <td>
                        <div style={{display: "flex", gap: "5px"}}>
                         {ele["collected"] === 1 ? 
                         <button className={`${styles["gray"]} ${styles["tab-btn"]}`} >تحصيل</button>
                         : 
                         <button className={`${styles["tab-btn"]}`} onClick={()=>collect(ele["id"])} >تحصيل</button>
                         }
                         {ele["delivered"] === 1 ? 
                         <button className={`${styles["gray"]} ${styles["tab-btn"]}`} >شحن</button>
                         : 
                         <button className={`${styles["tab-btn"]}`} onClick={()=>deliver(ele["id"])} >شحن</button>
                         }
                          <button className={styles["tab-btn"] + " del"} onClick={()=>deleteOrder(ele["id"])}>حذف</button>
                        </div>
                        </td>
        
        


                    </tr>
                })
            }
        </tbody>
      </table>
      <Toaster
              position="top-left"
              reverseOrder={false}
            />
        </div>  
              {
                addOrderWidgetState && (
                  <>
                    <Overlay />
                    <AddOrderPopUp/>
                  </>
                )}
    </div>
      {overlay ? <Overlay></Overlay> : null}
    </>
}

export default Orders;