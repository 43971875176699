import "../assets/css/sellpoints.css"
import { useNavigate } from "react-router-dom";

function SellPoint(){
    const navigate = useNavigate();
    return <div className="sellpoints">
        <div className="btn b1" onClick={()=>navigate("customer-invoice")}>فتح فاتورة مستهلك</div>
        <div className="btn b2" onClick={()=>navigate("seller-invoice")}>فتح فاتورة تاجر</div>
    </div>
}


export default SellPoint;