import styles from "../../../assets/css/popups/addinvoicepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function ChangePasswordPopup(props) {
  const [data, setData] = useState({
    password: "",
  });
  const [overlay, setOverlayState] = useState(false);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { changePasswordWidgetState: false } });
  }
  async function changePassword(){
      if (data.password.length === 0) {
        Toast.error("يجب إدخال الباسورد الجديد أولا")
      } else {
        data["name"] = props.selectedEmployeeName
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.changePassword(data);
        setOverlayState(false);
        if (!res) {
            Toast.error("حدث خطأ.");
        } else {
            Toast.success("تم تغيير الباسورد")
        }
      }
  }

  return (
    <>
      <div className={styles["popup"]}>
        <div className={styles.header}>
          <h2 className={styles["title"]}>تغيير باسورد  ({props.selectedEmployeeName})</h2>
          <div onClick={() => cancle("editDataWidgetState")}>
            <BackBtn />
          </div>
        </div>
          <input
            className={styles["pop-up-input"]}
            value={data.password}
            onChange={(ele) => {
              setData({ ...data, password: ele.target.value });
            }}
            placeholder="الباسورد الجديد"
          ></input>
      <button onClick={changePassword}>حفظ</button>

      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default ChangePasswordPopup;
