import Bar from "../components/bars";
import Orders from "../components/orders";

function OrdersPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"الأوردرات": "/orders"}
    ]
    return (
        <>
           <Bar pagename="الشحنات" notification={"1"} breadCrump={breadCrump} child={<Orders
           />}/>
        </>
    )
}

export default OrdersPage;