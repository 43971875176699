

function Overlay(){
    const overlayStyle = {
        position: "absolute",
        inset: "0",
        backgroundColor: "#999",
        mixBlendMode: "multiply",
  
    }
    return <div style={overlayStyle}></div>
}

export default Overlay;