import styles from "../../../assets/css/popups/editcategorypricepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function AddReturnPopUp() {
  const [data, setData] = useState({
    "invoice-code": "",
    "product-code": "",
    "return-price": "",
    date: ""
  });
  const [overlay, setOverlayState] = useState(false);

  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { addReturnWidgetState: false } });
  }
  async function add(){
      if (data.date.length !==0 && data["product-code"].length !==0  && data["invoice-code"].length !==0 && data["return-price"].length !==0) {
        data["date"] = data["date"].split("-").reverse().join("-");
        let functions = new JSFunctions();
        setOverlayState(true);
        let response = await functions.addReturn(data);
        setOverlayState(false);
        if (response.state === true) {
            Toast.success("تم إضافة المرتجع بنجاح");
        }else {
            Toast.error(response.state);
        }
      } else {
        Toast.error("يجب إدخال البيانات أولا")
      }
  }
  return (
  <>
        <div className={styles["popup"]}>
            <div className={styles.header}>
          <h2 className={styles["title"]}>إضافة مرتجع</h2>
          <div onClick={() => cancle("addReturnWidgetState")}>
            <BackBtn />
          </div>
            </div>
            <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data["invoice-code"]}
            onChange={(ele) => {
              setData({ ...data, 'invoice-code': ele.target.value });
            }}
            placeholder="كود الفاتورة"
          ></input>
                   <input
            className={styles["pop-up-input"]}
            value={data["product-code"]}
            onChange={(ele) => {
              setData({ ...data, "product-code": ele.target.value });
            }}
            placeholder="السيريال"
          ></input>
            </div>
            <div className={styles["gp"]}>
                <input
                    className={styles["pop-up-input"]}
                    value={data['return-price']}
                    onChange={(ele) => {
                    setData({ ...data, 'return-price': ele.target.value });
                    }}
                    placeholder="سعر المرتجع"
                ></input>
                        <input
                    className={styles["pop-up-input"]}
                    type="date"
                    value={data.date}
                    onChange={(ele) => {
                    setData({ ...data, date: ele.target.value });
                    }}
                    placeholder="التاريخ"
                ></input>
            </div>
       <button onClick={add}>حفظ</button>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default AddReturnPopUp;
