import styles from "../../../assets/css/popups/deleteproductpopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay"

function DeleteReturnPopup() {
  const [data, setData] = useState({
        serial: "",
  });
  const [overlay, setOverlayState] = useState(false);

  let state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: {"deletereturn_code": "" } });
    dispatch({ type: "", payload: {"delReturnWidgetState": false } });
  }
  async function DeleteProduct(){
    let serial = data.serial;
    if (serial.length === 0) {
        Toast.error("يجب إدخال سيريال المنتج أولا.")
    }else {
        let functions = new JSFunctions();
        setOverlayState(true);
        let res = await functions.deleteReturn(serial,1);
        setOverlayState(false);
        if (res.state === "Not found") {
          Toast.error("لم يتم العثور علي منتج بهذا السيريال.");
        }else {
          Toast.success("تم حذف المرتجع.");
          window.location.reload()
        }
    }
  }
  const handlePress = (e) => {
    let condition = "Enter";
    if (!state.toggle) {
      condition = "Control"
    };
    if (e.key === condition) {
        DeleteProduct();
      }
    }
    useEffect(()=>{
      if (state.deletereturn_code) {
        setData({serial: state.deletereturn_code})
      }
    },[])
  return (
    <>
 <div className={styles["popup"]}>
      <div className={styles.header}>
        <h2 className={styles["title"]}>حذف مرتجع</h2>
        <div onClick={() => cancle()}>
          <BackBtn />
        </div>
      </div>
      <div className={styles["gp"]}>
        <input
          className={styles["pop-up-input"]}
          value={data.serial}
          onKeyDown={(e)=>{handlePress(e)}}
          onChange={(ele) => {
            setData({ ...data,serial: ele.target.value });
          }
          }
          placeholder="سيريال المنتج"
        ></input>
        <button onClick={()=>DeleteProduct()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" fill="none">
        <path d="M29.32 10.4118V10.4132V21.5865C29.32 23.9137 28.6283 25.7112 27.4131 26.9263C26.198 28.1414 24.4006 28.8332 22.0734 28.8332H10.9134C8.58613 28.8332 6.78886 28.1414 5.5738 26.9248C4.35866 25.7082 3.66669 23.9074 3.66669 21.5732V10.4132C3.66669 8.08594 4.35845 6.28852 5.57357 5.07339C6.7887 3.85827 8.58612 3.1665 10.9134 3.1665H22.0867C24.414 3.1665 26.2112 3.85831 27.4246 5.07317C28.6378 6.28793 29.3264 8.08488 29.32 10.4118ZM21.3336 20.8334C21.9155 20.2515 21.9155 19.2949 21.3336 18.713L18.6205 15.9998L21.3336 13.2867C21.9155 12.7048 21.9155 11.7482 21.3336 11.1663C20.7516 10.5844 19.7951 10.5844 19.2131 11.1663L16.5 13.8794L13.7869 11.1663C13.205 10.5844 12.2484 10.5844 11.6665 11.1663C11.0845 11.7482 11.0845 12.7048 11.6665 13.2867L14.3796 15.9998L11.6665 18.713C11.0845 19.2949 11.0845 20.2515 11.6665 20.8334C11.9652 21.1321 12.3479 21.2732 12.7267 21.2732C13.1055 21.2732 13.4882 21.1321 13.7869 20.8334L16.5 18.1203L19.2131 20.8334C19.5118 21.1321 19.8946 21.2732 20.2734 21.2732C20.6521 21.2732 21.0349 21.1321 21.3336 20.8334Z" fill="white" stroke="white"/>
        </svg>
          حذف
        </button>
      </div>
     
    </div>
    <Toaster
              position="top-left"
              reverseOrder={false}
            />
    {overlay? <Overlay></Overlay> : null}
    </>
  );
}

export default DeleteReturnPopup;
