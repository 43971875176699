import styles from "../../../assets/css/popups/editcategorypricepopup.module.css";
import BackBtn from "../../backbtn";
import { JSFunctions } from "../../../App";
import { useDispatch } from "react-redux";
import Toasts from "../../../assets/toasts/toasts";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import Overlay from "../../overlay";

function AddOrderPopUp() {
  const [data, setData] = useState({
    invoice_code: "",
    policy_number: "",
    collection_amount: "",
    shipping_amount: "",
    date: ""
  });
  const [overlay, setOverlayState] = useState(false);
  const dispatch = useDispatch();
  const Toast = new Toasts();

  function cancle() {
    dispatch({ type: "", payload: { addOrderWidgetState: false } });
  }
  async function add(){
      if (data.invoice_code.length !==0 && data.policy_number.length !==0  && data.collection_amount.length !==0 && data.shipping_amount.length !== 0 && data.date.length !==0) {
        data["date"] = data["date"].split("-").reverse().join("-");
        let functions = new JSFunctions();
        setOverlayState(true);
        let response = await functions.addOrder(data);
        setOverlayState(false);
        if (response.state === "added") {
            Toast.success("تم إضافة الأوردر بنجاح");
        }else {
            Toast.error(response.state);
        }
      } else {
        Toast.error("يجب إدخال البيانات أولا")
      }
  }
  return (
  <>
        <div className={styles["popup"]}>
            <div className={styles.header}>
          <h2 className={styles["title"]}>إضافة شحنة</h2>
          <div onClick={() => cancle("addOrderWidgetState")}>
            <BackBtn />
          </div>
            </div>
            <div className={styles["gp"]}>
          <input
            className={styles["pop-up-input"]}
            value={data.invoice_code}
            onChange={(ele) => {
              setData({ ...data, invoice_code: ele.target.value });
            }}
            placeholder="كود الفاتورة"
          ></input>
          <input
            className={styles["pop-up-input"]}
            value={data.policy_number}
            onChange={(ele) => {
              setData({ ...data, policy_number: ele.target.value });
            }}
            placeholder="رقم البوليصة"
          ></input>

            </div>
            <div className={styles["gp"]}>
                <input
                    className={styles["pop-up-input"]}
                    value={data.collection_amount}
                    type="number"
                    onChange={(ele) => {
                    setData({ ...data, collection_amount: ele.target.value });
                    }}
                    placeholder="مبلغ التحصيل"
                ></input>
                <input
            className={styles["pop-up-input"]}
            value={data.shipping_amount}
            onChange={(ele) => {
              setData({ ...data, shipping_amount: ele.target.value });
            }}
            placeholder="مبلغ الشحن"
          ></input>
            </div>
            <div className={styles["gp"]}>
                <input
                    className={styles["pop-up-input"]}
                    value={data.date}
                    type="date"
                    onChange={(ele) => {
                    setData({ ...data, date: ele.target.value });
                    }}
                    placeholder="التاريخ"
                ></input>
            </div>
       <button onClick={add}>حفظ</button>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default AddOrderPopUp;
