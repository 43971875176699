import Bar from "../components/bars";
import Employees from "../components/employees";

function EmployeesPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"الموظفين": "/employees"}
    ]
    return <Bar pagename="الموظفين" breadCrump={breadCrump} child={<Employees/>}></Bar>
}

export default EmployeesPage;