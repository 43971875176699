import Bar from "../components/bars";
import Expenses from "../components/expenses";

function ExpensesPage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"مصروفات المحل": "/expenses"}
    ]
    return (
        <>
           <Bar pagename="مصروفات المحل" notification={"1"} breadCrump={breadCrump} child={<Expenses/>}/>
        </>
    )
}

export default ExpensesPage;