import { JSFunctions } from "../App";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../assets/toasts/toasts";
import { useNavigate } from "react-router-dom";
import Bar from "../components/bars";
import Storage from "../components/storage";


function StoragePage(){
    let breadCrump  = [
        {"الرئيسية": "/welcome"},
        {"المخزن": "/storage"},
      ]
        return (
          <>
            <Bar pagename="المخزن" notification={"1"} breadCrump={breadCrump} child={<Storage/>}/>
          </>
        )
}

export default StoragePage;