import { useDispatch, useSelector } from "react-redux";
import "../assets/css/storage.css";
import SearchByCodePopUp from "./popups/SearchByCodePopup";
import Overlay from "./overlay";

import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";
import * as XLSX from 'xlsx/xlsx.mjs';

function WareHouseReport() {
  const Toast = new Toasts();
  const state = useSelector((state) => state);
  const [overlay, setOverlayState] = useState(false);
  const [controlBtns, setControlBtns] = useState(true);

  const [data, setData] = useState({ running_out: {} , sold_out: [], products_categories: [] , categories: []});

  let searchByCodeWidgetState = state.searchByCodeWidgetState;

  const dispatch = useDispatch();
  let handleBtns = (state) => {
    dispatch({ type: "", payload: { [state]: true } });
  };

  async function loadData() {
    let functions = new JSFunctions();
    setOverlayState(true);
    let res = await functions.createWareHouseReport("");
    res.products_categories = res.products_categories.sort((a, b) => b.num - a.num);
    res.categories = res.categories.sort((a, b) => b.num - a.num);
    setData(res);
    setOverlayState(false);
    if (!res.state) {
        Toast.error("حدث خطأ");
    }else if (res.state === "wrong login data or don't have a permission"){
        Toast.error("خطأ في بيناتات الموظف أو عدم وجود تصريح")
      }
  }
  function createExcel(){
    function transformData(data) {
        // Initialize an empty object to store category information
        const categories = {};
    
        // Iterate over the data array
        data.forEach(item => {
            // Get the category name from the current item
            const categoryName = item.category || "Uncategorized";
    
            // If the category doesn't exist in the categories object, initialize it
            if (!categories[categoryName]) {
                categories[categoryName] = {
                    category_name: categoryName,
                    no: 0,
                    products: []
                };
            }
            // Increment the count of items in the category
            categories[categoryName].no++;
            categories[categoryName].products.push(item);
        });
    
        // Convert the categories object into an array
        const result = Object.values(categories);
    
        return result;
    }
    var wb = XLSX.utils.book_new();
    var sheet1 = XLSX.utils.json_to_sheet(data["all_products"]); // Flatten the nested arrays
    XLSX.utils.book_append_sheet(wb, sheet1, "جميع المنتجات");

    var sheet2 = XLSX.utils.json_to_sheet(data["products_categories"]); // Flatten the nested arrays
    XLSX.utils.book_append_sheet(wb, sheet2, "المنتجات بالعدد");

    var sheet3 = XLSX.utils.json_to_sheet(data["categories"]); // Flatten the nested arrays
    XLSX.utils.book_append_sheet(wb, sheet3, "الأصناف");

    var sheet4 = XLSX.utils.json_to_sheet(Object.values(data["running_out"])); // Flatten the nested arrays
    XLSX.utils.book_append_sheet(wb, sheet4, "منتجات علي وشك النفاذ");

    
    var sheet5 = XLSX.utils.json_to_sheet(data["sold_out"].map(item => ({ name: item }))); // Flatten the nested arrays
    XLSX.utils.book_append_sheet(wb, sheet5, "منتجات نفذت");

    // Loop through each transformed element and append to the Excel sheet
    transformData(data.all_products).forEach((ele) => {
        const sheetData = [];
        sheetData.push(["name", "no"]);
        sheetData.push([ele["category_name"], ele["no"]]);

        sheetData.push(["الإسم", "السيريال","الموكل", "الضمان", "سعر الشراء", "سعر البيع", "الصنف", "التاريخ"]);

        ele.products.forEach((ele)=>{
            sheetData.push( Object.values(ele))
        })

        const sheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(wb, sheet, ele["category_name"]);
});

    XLSX.writeFile(wb, "output.xlsx");
  }
  useEffect(() => {
      loadData();
  }, []);
  return (
    <>
      <div className="storage">
        <div className="btns">
            <div className="btn" onClick={createExcel} style={{backgroundColor: 'var(--white)', color: "var(--green)", border: "1px solid var(--green)"}}> 
            <img width="32" height="32" src="https://img.icons8.com/fluency/48/microsoft-excel-2019.png" alt="microsoft-excel-2019"/>
            إنشاء إكسيل
            </div>
        </div>
        <br></br>
        {Object.keys(data.running_out).length === 0 ? (
          <h4>منتجات علي وشك النفاذ (لا يوجد)</h4>
        ) : (
          <>
            <h4>منتجات علي وشك النفاذ</h4>
            <div className="table-parent">
              <table>
                <thead>
                  <tr>
                    <th>التسلسل</th>
                    <th>الإسم</th>
                    <th>العدد</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.running_out).map((key, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.running_out[key].name}</td>
                      <td>{data.running_out[key].num}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {data.sold_out.length === 0 ? (
          <h4>منتجات نفذت (لا يوجد)</h4>
        ) : (
          <>
          <h4 style={{color: "var(--red)"}}>منتجات نفذت</h4>
            <div className="table-parent">
              <table>
                <thead>
                  <tr>
                    <th>التسلسل</th>
                    <th>الإسم</th>
                  </tr>
                </thead>
                <tbody>
                  {data.sold_out.map((key, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.sold_out[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {data.categories.length === 0 ? (
          <h4>الأصناف (لا يوجد)</h4>
        ) : (
          <>
          <h4>الأصناف</h4>
            <div className="table-parent">
              <table>
                <thead>
                  <tr>
                    <th>التسلسل</th>
                    <th>الصنف</th>
                    <th>العدد</th>
                  </tr>
                </thead>
                <tbody>
                  {data.categories.map((ele, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{ele["name"]}</td>
                      <td>{ele["num"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {data.products_categories.length === 0 ? (
          <h4>المنتجات بالعدد (لا يوجد)</h4>
        ) : (
          <>
          <h4>المنتجات بالعدد</h4>
            <div className="table-parent">
              <table>
                <thead>
                  <tr>
                    <th>التسلسل</th>
                    <th>الإسم</th>
                    <th>الصنف</th>
                    <th>العدد</th>

                  </tr>
                </thead>
                <tbody>
                  {data.products_categories.map((ele, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{ele["product_name"]}</td>
                      <td>{ele["category"]}</td>
                      <td>{ele["num"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

   
        {searchByCodeWidgetState && (
          <>
            <Overlay />
            <SearchByCodePopUp />
          </>
        )}
      </div>
      {overlay ? <Overlay></Overlay> : null}
    </>
  );
}

export default WareHouseReport;
