import SideBar from "./bars";


function Dashboard(){
    return (
        <>
            <SideBar></SideBar>
        </>
    )
}

export default  Dashboard;