
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/css/returns.module.css"
import Overlay from "./overlay";
import { JSFunctions } from "../App";
import { useEffect, useState } from "react";
import Toasts from "../assets/toasts/toasts";
import AddReturnPopUp from "./popups/Returns/addReturnPopUp";
import { Toaster } from "react-hot-toast";
import DeleteReturnPopup from "./popups/Returns/delReturnPopUp";

function Returns(){
    const Toast = new Toasts();
    const state = useSelector(state => state);
    const [overlay, setOverlayState] = useState(false);
    const [data, setData] = useState({
        date: "",
        returns: []
    });
    const addReturnWidgetState = state.addReturnWidgetState;
    const delReturnWidgetState = state.delReturnWidgetState;


    const dispatch = useDispatch();
    async function search(){
        if (data.date.length !== 0 ) {
            let functions = new JSFunctions();
            setOverlayState(true);
            console.log(data.date.split("-").reverse().join("-"))
            let res = await functions.returnsSearchDate(data.date.split("-").reverse().join("-"));
            setOverlayState(false);
            if (res.state === "not_found") {
              Toast.error("لم يتم العثور علي مرتجعات");
            }else {
              setData({...data, returns: res})
          }
        }else {
            Toast.error("يجب إدخال التاريخ أولا")
        }
    }
    async function loadData(){
      let functions = new JSFunctions();
          setOverlayState(true);
          let res = await functions.returnsSearchDate("");
          console.log(res)
          setOverlayState(false);
          if (res.state === "not_found") {
            Toast.error("لم يتم العثور علي مرتجعات");
          }else {
            setData({...data, returns: res})
        }
    }
    useEffect(()=>{
  
      loadData();
    },[])
    return <>
    <div className={styles["returns"]}>
    <input className={styles["date-search"]} type="date" 
            value={data.date}
            onChange={(ele) => {
              setData({ ...data, 'date': ele.target.value });
            }}></input>
        <div className={styles["btns"]}>
            <button className="btn" onClick={search} >
            بحث  
            </button>
            <button className="btn" onClick={(search)=>{dispatch({type: "", payload: {addReturnWidgetState: true}})}}>
             إضافة مرتجع
            </button>
            <button className="btn" onClick={()=>dispatch({type: "", payload: {delReturnWidgetState: true}})} >
             حذف مرتجع
            </button>
        </div>
        <div className="table-parent">
        <table>
        <thead>
            <tr>
                <th>التسلسل</th>
                <th>التايخ</th>
                <th>كود المنتج</th>
                <th>إسم المنتج</th>
                <th>سعر الشراء</th>
                <th>سعر المرتجع</th>
                <th>فرق الخسارة</th>
            </tr>
        </thead>
        <tbody>
            {
                data.returns.map((ele,index)=>{
                    ele = JSON.parse(ele);
                    return <tr>
                        <td>{index + 1}</td>
                        <td>{ele["date"]}</td>
                        <td>{ele["serial"]}</td>
                        <td>{ele["name"]}</td>
                        <td>{ele["price"]}</td>
                        <td>{ele["returnprice"]}</td>
                        <td>{+ele["price"] - +ele["returnprice"]}</td>
                        <button className="del" onClick={()=>dispatch({type: "", payload: {delReturnWidgetState: true,deletereturn_code: ele['serial']}})}>
                          حذف
                        </button>
                    </tr>
                })
            }
        </tbody>
      </table>
      <Toaster
              position="top-left"
              reverseOrder={false}
            />
        </div>  
              {
                addReturnWidgetState && (
                  <>
                    <Overlay />
                    <AddReturnPopUp />
                  </>
                )}
                {
                delReturnWidgetState && (
                  <>
                    <Overlay />
                    <DeleteReturnPopup />
                  </>
                )}
    </div>
      {overlay ? <Overlay></Overlay> : null}
    </>
}

export default Returns;